<div class="card">
    <div class="card-header">
      <div>
        <h2 class="font-weight-bolder mb-0">{{value}}</h2>
        <p class="card-text">{{topic}}</p>
      </div>
      <div class="avatar bg-light-{{iconColor}} p-50 m-0">
        <div class="avatar-content">
          <i [data-feather]="icon" class="font-medium-5"></i>
        </div>
      </div>
    </div>
</div>