<canvas class="doughnut-chart-ex chartjs mx-auto" baseChart 
    [datasets]="doughnutChart.datasets"
    [options]="doughnutChart.options" 
    [chartType]="doughnutChart.chartType">
</canvas>
<div class="d-flex justify-content-between mt-3 mb-1" *ngFor="let detail of detailList">
    <div class="d-flex align-items-center">
        <i [data-feather]="detail.icon" class="font-medium-2 text-{{detail.iconColor}}"></i>
        <span class="font-weight-bold ml-75 mr-25">{{detail.attribute}}</span>
        <span>: {{detail.value}} {{detail.unit?detail.unit: ''}}</span>
    </div>
    <!-- <div>
        <span>2%</span>
        <i data-feather="arrow-up" class="text-success"></i>
    </div> -->
</div>