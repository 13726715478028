import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from '@fake-db/fake-db.service';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { coreConfig } from 'app/app-config';
// import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from 'app/auth/helpers';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { UserService } from './core/auth/services/user.service';
import { AuthService } from './core/auth/services/auth.service';
import { InstallationService } from './core/installation/services/installation.service';
import { SensorDataService } from './core/device/services/sensor-data.service';
import { ScheduledJobService } from './core/scheduled-job/services/scheduled-job.service';
import { ScheduledJobStatusService } from './core/scheduled-job/services/scheduled-job-status.service';
import { AlarmService } from './core/alarm/services/alarm.service';
import { AlarmActionService } from './core/alarm/services/alarm-action.service';
import { AlarmStatusService } from './core/alarm/services/alarm-status.service';
import { environment } from '../environments/environment';
import { ToastService } from './main/components/toasts/toasts.service';
import { SharedModule } from './modules/shared/shared.module';
import { BlockUIModule } from 'ng-block-ui';
import { CommentTypeService } from './core/alarm/services/comment-type.service';
import { AlarmInstallationReportService } from './core/alarm/services/alarm-installation-report.service';
import { AlarmInstallationDeviceReportService } from './core/alarm/services/alarm-installation-device-report.service';
import { CustomPieComponent } from './modules/shared/charts/custom-pie/custom-pie.component';
import { InstallationMapComponent } from './modules/shared/components/installation-map/installation-map.component';
import { TwilioCallService } from './core/call/services/twilio-call.service';
import { TwilioCallStatusService } from './core/call/services/twilio-call-status.service';
import { DeviceService } from './core/device/services/device.service';
// import { NgxCaptureModule } from 'ngx-capture';
import { RoleGuard } from './core/auth/guards/role.guard';
import { Role } from './auth/models';
import { AuthenticationService } from './auth/service';
import { DeviceTypeService } from './core/device/services/device-type.service';
import { ScheduledJobTypeService } from './core/scheduled-job/services/scheduled-job-type.service';
import { ScheduledJobWeekDayService } from './core/scheduled-job/services/scheduled-job-week-day.service';
// import { SwiperDirective } from './directives/swiper.directive';
import { register } from 'swiper/element/bundle';
import { ScheduledJobActionService } from './core/scheduled-job/services/scheduled-job-action.service';
register();
const appRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'alarms',
    loadChildren: () => import('./modules/alarms/alarms.module').then(m => m.AlarmsModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [RoleGuard],
    data: {
      roles: [Role.Admin, Role.PlatformAdmin]
    }
  },
  {
    path: 'scheduled-jobs',
    loadChildren: () => import('./modules/scheduled-jobs/scheduled-jobs.module').then(m => m.ScheduledJobsModule),
    canActivate: [RoleGuard],
    data: {
      roles: [Role.Admin, Role.PlatformAdmin]
    }
  },
  {
    path: 'apps',
    loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'components',
    loadChildren: () => import('./main/components/components.module').then(m => m.ComponentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'extensions',
    loadChildren: () => import('./main/extensions/extensions.module').then(m => m.ExtensionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forms',
    loadChildren: () => import('./main/forms/forms.module').then(m => m.FormsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tables',
    loadChildren: () => import('./main/tables/tables.module').then(m => m.TablesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'charts-and-maps',
    loadChildren: () => import('./main/charts-and-maps/charts-and-maps.module').then(m => m.ChartsAndMapsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
    declarations: [
        AppComponent,
        ContextMenuComponent,
        BasicCustomContextMenuComponent,
        AnimatedCustomContextMenuComponent,
        SubMenuCustomContextMenuComponent,        
    ],
    imports: [
        BrowserModule,
        provideFirebaseApp(() =>
          initializeApp(environment.firebase)
        ),
        provideMessaging(() => getMessaging()),
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        }),
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        SharedModule,
        BlockUIModule.forRoot(),
        // NgxCaptureModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // ! IMPORTANT: Provider used to create fake backend, comment while using real API
        fakeBackendProvider,
        UserService,
        AuthService,
        InstallationService,
        SensorDataService,
        ScheduledJobService,
        ScheduledJobStatusService,
        AlarmService,
        AlarmActionService,
        AlarmStatusService,
        ToastService,
        CommentTypeService,
        AlarmInstallationReportService,
        AlarmInstallationDeviceReportService,
        TwilioCallService,
        TwilioCallStatusService,
        DeviceService,
        AuthenticationService,
        DeviceTypeService,
        ScheduledJobTypeService,
        ScheduledJobWeekDayService,
        ScheduledJobActionService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
