import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-horizontal-card',
  templateUrl: './stats-horizontal-card.component.html',
  styleUrls: ['./stats-horizontal-card.component.scss']
})
export class StatsHorizontalCardComponent implements OnInit {
  @Input() value: number|string;
  @Input() topic: string;
  @Input() icon: string;
  @Input() iconColor: string = 'primary';

  constructor() { }

  ngOnInit(): void {
  }

}
