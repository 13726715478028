// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
// import { tap } from 'rxjs/operators';
// NGRX
// import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
// import { AppState} from '../../../core/reducers/';
// import { isLoggedIn } from '../_selectors/auth.selectors';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
        let path = state.url;
        if(path.includes('auth')) return of(true);
        if(!this.authService.isAuthenticated()){
            console.log("NO ESTA AUTENTICADO -> GUARD MANDA A LOGIN")
            this.router.navigateByUrl('/auth/login');
        }
        return of(true);
        // this.store
        //     .pipe(
        //         select(isLoggedIn),
        //         tap(loggedIn => {
        //             if (!loggedIn) {
        //                 this.router.navigateByUrl('/auth/login');
        //             }
        //         })
        //     );
    }
}
