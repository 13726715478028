import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_SENSOR_DATA_URL } from '../../core.global';
import { BasicService } from '../../_base/services/basic.service';

@Injectable()
export class SensorDataService extends BasicService{
    baseUrl:string = '';
    
    constructor(protected http: HttpClient){
        super(http);
        this.baseUrl = API_SENSOR_DATA_URL;
    }

    instant(filter){
        let queryParams =  new HttpParams();
        Object.keys(filter).forEach((field)=>{
            queryParams = queryParams.append(field, filter[field]);
        })
        return this.http.get(`${this.baseUrl}/instant`, {params: queryParams})
    }
}