import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// import { API_USERS_URL } from '../../connection.data';
import { environment } from '../../../../environments/environment';
import { UserService } from './user.service';
import { AuthUtils } from '../util/auth.util';

@Injectable()
export class AuthService{
    // baseUrl:string = '';
    private user: any;
    private token: any;
    authenticated$ = new BehaviorSubject(false);
    signout$ = new Subject();
    mapMode: string = 'satellite';
    // private lastLoginDate: Date;
    // private LOGIN_TIMEOUT_MS = 60*60*1000;
    
    constructor(){
        // this.baseUrl = API_USERS_URL;
        this.rememberLastLogin();
        console.log("constructor auth service")
    }

    rememberLastLogin(){
        // console.log("remember last login")
        if(this.isAuthenticated()) return;
        // const doesRemember = Boolean(localStorage.getItem(environment.rememberLastLogin));
        // if(doesRemember){
            const userStr = localStorage.getItem(environment.user);
            console.log(userStr);
            if(userStr!=null && userStr!='undefined') this.setUser(JSON.parse(userStr));
            // const lastLoginDateStr = Number(localStorage.getItem(environment.lastLoginDate));
            // if(!isNaN(lastLoginDateStr)) this.lastLoginDate = new Date(Number(lastLoginDateStr));
            const token = localStorage.getItem(environment.token);
            
            if(token!=null) this.setToken(token);
            
            if(!this.isAuthenticated()){
                this.signOut();
            }else{
                console.log(`last map mode: ${localStorage.getItem(environment.mapMode)}`);
                this.mapMode = localStorage.getItem(environment.mapMode) || 'satellite';
                console.log(`new map mode: ${this.mapMode}`)
                this.authenticated$.next(this.user);
            }
        // }
    }

    //Se ha marcado "recuerdame" y por tanto recuerda el nombre de usuario para formulario
    doesRememberLogin(){
        const doesRemember = localStorage.getItem(environment.rememberLastLogin) == 'true';
        console.log("does remember", doesRemember, localStorage.getItem(environment.rememberLastLogin))
        return doesRemember;
    }

    rememberLastUserLogin(){
        return localStorage.getItem(environment.login);
    }

    //Recuerda el ultimo usuario logueado por tanto es posible saltarse el login
    doesRemember(){
        const userStr = localStorage.getItem(environment.user);
        // console.log(userStr);
        if(userStr==null || userStr=="") return false;
        // const lastLoginDateStr = Number(localStorage.getItem(environment.lastLoginDate));
        // if(!isNaN(lastLoginDateStr)) this.lastLoginDate = new Date(Number(lastLoginDateStr));
        const token = localStorage.getItem(environment.token);
        // console.log(token);
        if(token==null || token=="")return false;

        // if(AuthUtils.isTokenExpired(token)) return false;
        return true;
    }

    markForRemember(){
        localStorage.setItem(environment.rememberLastLogin, `${true}`);
        localStorage.setItem(environment.login, this.user.login);
    }

    markForNotRemember(){
        localStorage.setItem(environment.rememberLastLogin, `${false}`);
    }

    // login(user: any){
    //     // console.log("login")
    //     // console.log(user)
    //     return this.userService.login(user).pipe(
    //         tap((response: any)=>{
    //             // console.log("login response: ");console.dir(response);
    //             if(response.status && response.status=='success'){
    //                 this.setUser(response.user);
    //                 this.setToken(response.token);
    //                 this.authenticated$.next(response.user);
    //                 // console.log("login: is authenticated now ?");console.log(this.isAuthenticated());
    //                 // this.setLastLoginDate(new Date())
    //             }
    //         })
    //     )
    // }

    // setLastLoginDate(date){
        // this.lastLoginDate = date;
        // localStorage.setItem(environment.lastLoginDate, `${date.getTime()}`);
    // }

    setUser(user: any){
        // console.log("set user to");console.dir(user);
        this.user = user;
        localStorage.setItem(environment.user, JSON.stringify(user));
    }

    setToken(token: string){
        // console.log("set token to");console.dir(token);
        this.token = token;
        localStorage.setItem(environment.token, token);
    }

    getToken(){
        return this.token;
    }

    getUser(){
        return this.user;
    }

    signOut(){
        // console.log("signout");
        this.token = null;
        this.user = null;
        // localStorage.removeItem(environment.token);
        // localStorage.removeItem(environment.user);
        this.signout$.next(true);
    }

    setMapMode(mapMode){
        this.mapMode = mapMode;
        localStorage.setItem(environment.mapMode, mapMode);
    }

    isAuthenticated(){
        // const now = new Date();
        // console.log(this.user!=null && this.token!=null && this.lastLoginDate != null)
        // if(this.user!=null && this.token!=null && this.lastLoginDate != null){
        //     console.log(`now: ${now.toLocaleString('sv')}`)
        //     console.log(`login time: ${this.lastLoginDate.toLocaleString('sv')}`)
        //     console.log(`substraction: ${now.getTime() - this.lastLoginDate.getTime()} < ${this.LOGIN_TIMEOUT_MS}`)
        //     console.log((now.getTime() - this.lastLoginDate.getTime() > this.LOGIN_TIMEOUT_MS))
        // }
        console.log("is authenticated?");
        console.log("user: ");console.dir(this.user);
        console.log("token: ");console.dir(this.token);
        console.log("is token expired?: "+AuthUtils.isTokenExpired(this.token));
        console.log("is authenticated: "+(this.user!=null && this.token!=null && !AuthUtils.isTokenExpired(this.token)))
        return this.user!=null && this.token!=null && !AuthUtils.isTokenExpired(this.token);
    }
}