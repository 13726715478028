import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// import { NavController, ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
//import { ApiProviderService } from '../../../../core/auth/_services/api-provider.service';
import { User} from '../../../../core/auth/_models/user';
import { Router } from '@angular/router';
// import {ModalPictureComponent} from '../../@componente/modal-picture/modal-picture.component';
// import { from } from 'rxjs';
// import { FirmaComponent } from '../../@componente/firma/firma.component';
import {DataService} from '../../../../core/auth/_services/data.service';
import {SafetekInstallation} from '../../../../core/auth/_models/installationData';
// import {PictureType} from '../../../../core/auth/_models/PictureType'
// import {LocalStorageService} from '../../../../core/auth/_services/local.storage.service'
import { GLOBAL_AUTH_SERVER_ADDRESS_SSL, on_line_enabled } from '../../../../core/core.global'
import { Chart } from 'chart.js';
import { ApiProviderService } from '../../../../core/auth/_services/api-provider.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AlarmService } from '../../../../core/alarm/services/alarm.service';
import { AlarmActionService } from '../../../../core/alarm/services/alarm-action.service';
import { combineLatest, of } from 'rxjs';
import { timestamp } from '../../../../core/_base/util/date.util';
import * as _ from 'lodash'
import { SensorDataService } from '../../../../core/device/services/sensor-data.service';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss'],
})
export class CameraModalComponent implements OnInit {

  @Input() location: any;
  @Input() device: any;
  c_user : User;
  node;

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  thresholds = {
    temperature: 25.0,
    humidity: 75.0,
    co_ppm: 1,
    co_vgas: 20
  };

  segmentList = [
    // {value: "temperature", text: "Temperatura"},
    // {value: "co_ppm", text: "CO PPM"},
    // {value: "humidity", text: "Humedad"},
  ]

  titles = {
    temperature: 'Alarmas últimas 24 horas',
    humidity: 'Humedad últimas 24 horas',
    co_ppm: 'CO PPM últimas 24 horas',
    co_vgas: 'CO VGAS últimas 24 horas'
  }
  chart: any = {
    data: [],
    secureThreshold: 0,
    title: 'Alarmas últimas 24 horas',
    label: 'alarmas'
  };
  selectedSegment: string = "temperature";
  alarmList: any[] = [];
  alarmActionList: any[] = [];
  lastSensorData: any;
  slides: any = [
    // {img: 'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg'},
    // {img: 'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg'},
    // {img: 'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg'},
    // {img: 'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg'},
  ];
  constructor(
    // public navCtrl: NavController,
     private route: ActivatedRoute,
     //public api:ApiProviderService,
     public router: Router,
    //  public modalController: ModalController,
     private api:ApiProviderService,
     private dataService: DataService,
    //  private locaStorage : LocalStorageService,
     private cdr: ChangeDetectorRef,
     private alarmService: AlarmService,
     private alarmActionService: AlarmActionService,
     private sensorDataService: SensorDataService
  ) {
    this.c_user = {
      uid : Number(localStorage.getItem('uid')),
      name : localStorage.getItem('name'),
      group_id : localStorage.getItem('group_id'),
      maintenance_team_id : localStorage.getItem('maintenance_team_id'),
      partner_id : localStorage.getItem('partner_id'),
    };
    //this.dataService.set_pictureType(PictureType.WORKGUIDE);
  }
  
  ngOnInit() {
    //HERE I WILL CALL THE DB TO RETRIEVE DATA FOR THE INSTALLATION, CONTRACT AND SYSTEM, SUB-SYSTEMS
    var resp = this.route.params.subscribe(params => {
      this.node = {
        central_id : params['central_id'],
        central_status_name : params['central_status_name'],
        installation_name : params['installation_name'],
        last_data_time : params['last_data_time'],
      }
      console.log("node: ");
      console.dir(this.node);
      console.log("params: ");
      console.dir(params);

      this.fetchData();
    });
  }

  fetchData(){
    const st = new Date();
    st.setHours(0);
    st.setMinutes(0);
    st.setSeconds(0);
    let filterOp = {
      // location_id: this.location.id,
      st: timestamp(st),
      status_id: 1
    }
    if(this.device){
      filterOp['device_id'] = this.device.id;
    }else{
      filterOp['location_id'] = this.location.id;
    }
    combineLatest([
        this.alarmService.getPage({
        sortField: "id",
        sortOrder: "desc",
        pageSize: 0,
        pageNumber: 0,
        filter: filterOp
      } as any).pipe(
        tap((response)=>console.dir(response)),
        filter((response:any)=>response && response.items),
        map((response)=>response.items)
      ),
      this.alarmActionService.getAll().pipe(
        tap((alarmActionList)=>console.dir(alarmActionList)),
        filter((alarmActionList:any)=>alarmActionList && alarmActionList.length>0)
      )
    ]).pipe(
      tap(([alarmList, alarmActionList])=>{
        console.log("alarma y acciones")
        console.dir({alarmList, alarmActionList})
      }),
      // filter(([alarmList, alarmActionList])=>alarmList && alarmList.length>0 && alarmActionList && alarmActionList.length>0)
    ).subscribe(([alarmList, alarmActionList])=>{
      this.alarmList = alarmList.sort(function compare(a, b) {
        var dateA = new Date(a.create_date);
        var dateB = new Date(b.create_date);
        return dateA.getTime() - dateB.getTime();
      });
      this.alarmActionList = alarmActionList;
      this.slides = _.flatten(this.alarmList.filter(alarm=>alarm.EmailCam)
        .map(alarm=>alarm.EmailCam)).map(emailCam=>{
          return {
            img: `${GLOBAL_AUTH_SERVER_ADDRESS_SSL}/pics/${emailCam.file_name}`
          }
        })
      const data = this.generateAlarmData(alarmList);
      this.chart.data = data;
      console.log("chart: ", this.chart)
      // console.log("going to open alarm modal with alarm list");console.dir(alarmList);
      
    })
    // this.api.get_last_data_id(this.node.central_id)
    //     .then((data:any) => {
    //         console.log("data retrieved: ");
    //         console.dir(data);
    //         if (data && data.length && data.length>0){
    //           // this.graphs_data = data; 
    //           let values = [];
    //           data.forEach(row=>{
    //             const date = new Date(new Date(row.write_date).toLocaleString('es-CL'));
    //             this.chart.data = [];                
    //             values.push(
    //               {label: date.toLocaleTimeString(), value: parseFloat(row[this.selectedSegment])}
    //             );
                
    //           });
    //           this.chart.data = values;
    //           this.chart.title = this.titles[this.selectedSegment];
    //           this.chart.secureThreshold = this.thresholds[this.selectedSegment];
    //           this.chart.label = this.selectedSegment;
    //           console.log("chart ");console.dir(this.chart);
    //           const lastDate = new Date(data[data.length-1].write_date);
    //           this.node.last_data_time = formatDate(lastDate);
    //           this.cdr.detectChanges();
    //         }    
    //     });

    this.sensorDataService.instant({
      device_id: this.location.Devices[0].id
    }).subscribe((response:any)=>{
        console.log("response for instant")
        console.dir(response);
        const data = response.itemList;
        this.lastSensorData = null;
        if(data && data.length>0){
          this.lastSensorData = data[0];
          this.lastSensorData.create_date = timestamp(new Date(this.lastSensorData.create_date));
        }
    })
  }

  // generateAlarmData(alarmList:any[]){
  //   const data = [];

  //   //Ordena alarmas
  //   alarmList = _.sortBy(alarmList, [(alarm)=>new Date(alarm.create_date).getTime()])
  //   console.log("ordered alarm list: ");console.dir(alarmList);

  //   //Rellena data
  //   let prevHour = 0;
  //   for(let alarm of alarmList){
  //     const alarmDate = new Date(alarm.create_date);
  //     const alarmHours = alarmDate.getHours(); //* 60 + alarmDate.getMinutes();

  //     if(prevHour > alarmHours){
  //       continue;
  //     }

  //     alarmDate.setMinutes(0);
  //     alarmDate.setSeconds(0);
  //     for(let hour = prevHour; hour<alarmHours; hour++){
  //       const date = new Date(alarmDate);
  //       date.setHours(hour);
  //       data.push({
  //         value: 0,
  //         label: timestamp(date).split(' ')[1],
  //       })
  //     }
      
  //     data.push({
  //       value: 1,
  //       label: timestamp(alarmDate).split(' ')[1]
  //     });
  //     prevHour = alarmHours + 1;
  //   }

  //   for(let hour = prevHour; hour<=new Date().getHours(); hour++){
  //     const date = new Date();
  //     date.setHours(hour);
  //     data.push({
  //       value: 0,
  //       label: timestamp(date).split(' ')[1],
  //     })
  //   }

  //   return data;
  // }

  generateAlarmData(alarmList:any[]){
    const data = [];
    const st = new Date()
    const et = new Date()
    // if(this.minSt == null){
      st.setHours(0);st.setMinutes(0);st.setSeconds(0);st.setMilliseconds(0);
    // }
    // if(this.maxEt == null){
      // et.setHours(23);et.setMinutes(59);et.setSeconds(59);
    // }
    const currDate = new Date(st);
    let prevDate = null;
    //Ordena alarmas
    alarmList = _.sortBy(alarmList, [(alarm)=>new Date(alarm.create_date).getTime()])
    console.log("ordered alarm list: ");console.dir(alarmList);

    //Rellena data
    for(let alarm of alarmList){
      const alarmDate = new Date(alarm.create_date);
      
      alarmDate.setMinutes(st.getMinutes());
      alarmDate.setSeconds(0);alarmDate.setMilliseconds(0);

      if(currDate.getTime() > alarmDate.getTime()){
        continue;
      }

      // console.log([data.length>0, data.slice(-1)[0]['label'], timestamp(alarmDate).split(' ')[1], (data.length>0 && data.slice(-1)[0]['label'] == timestamp(alarmDate).split(' ')[1])])

      prevDate = new Date(currDate);

      while(currDate.getTime() < alarmDate.getTime()){
        data.push({
          value: 0,
          label: timestamp(currDate).split(' ')[1],
        })
        // console.log(`added ${timestamp(currDate)} with 0. Limit is ${timestamp(alarmDate)}`)
        // console.log(`${currDate.getTime()} < ${alarmDate.getTime()}`)
        currDate.setHours(currDate.getHours() + 1);
      }
      
      data.push({
        value: 1,
        label: timestamp(currDate).split(' ')[1]
      });
      // console.log(`added ${timestamp(currDate)} with 1. Alarm is at ${timestamp(alarmDate)}`)
      currDate.setHours(currDate.getHours() + 1);
    }
    while(currDate.getTime() < et.getTime()){
      data.push({
        value: 0,
        label: timestamp(currDate).split(' ')[1],
      })
      currDate.setHours(currDate.getHours() + 1);
    }

    return data;
  }

  goBack(){
    // this.modalController.dismiss();
    // this.navCtrl.back();
  }
  segmentChanged(event){
    this.selectedSegment = event.detail.value;
    console.dir(event.detail.value);
    this.fetchData();
  }

  selectVariable(event){
    this.selectedSegment = event.detail.value;
    console.dir(event.detail.value);
    this.fetchData();
  }
}

function formatDate(date: Date){
  const months = [
    "enero",
    "febrero", 
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre"
  ];
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado"
  ]
  let monthStr = months[date.getMonth()];

  let day = date.getDate();
  // let dayStr = numberTo2DigitsString(day);
  let weekDay = days[date.getDay()];

  let year = date.getFullYear();

  let hour = date.getHours();
  let hourStr = numberTo2DigitsString(hour);
  let minute = date.getMinutes();
  let minuteStr = numberTo2DigitsString(minute);
  

  return `${day} de ${monthStr} a las ${hourStr}:${minuteStr}`;
}

function numberTo2DigitsString(number:Number){
  if(this.number<10) return `0${number}`;
  return `${number}`;
}