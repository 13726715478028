import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { colors } from '../../../../colors.const';
import { CoreConfigService } from '../../../../../@core/services/config.service';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexMarkers, ApexPlotOptions, ApexStroke, ApexTheme, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import * as _ from 'lodash';

// interface ChartOptions
export interface ChartOptions {
  series?: ApexAxisChartSeries;
  chart?: ApexChart;
  xaxis?: ApexXAxis;
  dataLabels?: ApexDataLabels;
  grid?: ApexGrid;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  title?: ApexTitleSubtitle;
  colors?: string[];
  tooltip?: ApexTooltip;
  plotOptions?: ApexPlotOptions;
  yaxis?: ApexYAxis;
  fill?: ApexFill;
  labels?: string[];
  markers: ApexMarkers;
  theme: ApexTheme;
}

@Component({
  selector: 'app-custom-stepline',
  templateUrl: './custom-stepline.component.html',
  styleUrls: ['./custom-stepline.component.scss']
})
export class CustomSteplineComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data = [];
  @Input() title = "";
  @Input() threshold = 0;
  @Input() label = "";
  public isMenuToggled = false;

  @ViewChild('apexLineChartRef') apexLineChartRef: any;
  // Apex Line Area Chart
  apexLineChart: Partial<ChartOptions> = {
    series: [
      {
        name: '',
        data: []
      },
      // {
      //   data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50]
      // }
      {
        name: 'umbral',
        data: []
      }
    ],
    chart: {
      height: 400,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      strokeWidth: 2,
      strokeOpacity: 1,
      strokeColors: [colors.solid.white],
      colors: [colors.solid.danger]
    },
    colors: [colors.solid.danger],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'stepline',
      width: 2,
      dashArray: [0, 2]
    },
    xaxis: {
      categories: [
        // '7/12',
        //   '8/12',
        //   '9/12',
        //   '10/12',
        //   '11/12',
        //   '12/12',
        //   '13/12',
        //   '14/12',
        //   '15/12',
        //   '16/12',
        //   '17/12',
        //   '18/12',
        //   '19/12',
        //   '20/12',
        //   '21/12'
      ]
    },
    tooltip: {
      custom: function (data) {
        return (
          '<div class="px-1 py-50">' +
          '<span>' +
          data.series[data.seriesIndex][data.dataPointIndex] +
          '</span>' +
          '</div>'
        );
      }
    }
  };

  constructor(
    private _coreConfigService: CoreConfigService
  ) { }

  ngOnInit(): void {
    console.log("CUSTOM STEPLINE")
  }

  getWidth(){
    try{
    return this.isMenuToggled === false ? this.apexLineChartRef.offsetWidth : this.apexLineChart.chart.width
    }catch(err){}
    return _.get(this.apexLineChart, 'chart.width', 0);
  }

  /**
   * After View Init
   */
  ngAfterViewInit() {
    // Subscribe to core config changes
    this._coreConfigService.getConfig().subscribe(config => {
      // If Menu Collapsed Changes
      if (config.layout.menu.collapsed === true || config.layout.menu.collapsed === false) {
        setTimeout(() => {
          // Get Dynamic Width for Charts
          this.isMenuToggled = true;
          this.apexLineChart.chart['width'] = this.apexLineChartRef?.nativeElement.offsetWidth;
        }, 900);
      }
    });
  }

  dataLoaded = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.apexLineChart.xaxis.categories = [];
    this.apexLineChart.series[0].data = [];
    const data = [];
    const tdata = [];
    // this.apexLineChart.series[1].data = [];
    this.data.forEach((row:any)=>{
      data.push(row.value as number);
      this.apexLineChart.xaxis.categories.push(row.label);
      tdata.push(this.threshold);
    })
    this.apexLineChart.series[0].data = data;
    this.apexLineChart.series[0].name = this.label;
    this.apexLineChart.series[1].data = tdata;
    console.log("changes");
    console.log(this.data);
    this.dataLoaded = true;
  }
}
