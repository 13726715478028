<div class="card">
    <div class="card-header">
        <h4 class="card-title">{{title}}</h4>
    </div>
    <div class="card-body">
        <app-detail-pie
            [data]="data"
            [unit]="unit"
        ></app-detail-pie>
    </div>
</div>