import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
// import { Platform } from '@ionic/angular';
// import { Device } from '@ionic-native/device/ngx';
import {GLOBAL_AUTH_SERVER_ADDRESS_SSL,GLOBAL_AUTH_SERVER_ADDRESS} from '../../core.global'
@Injectable({
  providedIn: 'root'
})
export class ApiProviderService {
  AUTH_SERVER_ADDRESS:  string  =  GLOBAL_AUTH_SERVER_ADDRESS_SSL;       //TEST
  constructor(
    private  httpClient:  HttpClient,
    // private platform: Platform,
    // private device: Device
    ) { 
      // this.platform.ready().then(() => { 
      //   var regex = /^[6-8]{1}/g
      //   if (regex.test(this.device.version)){
      //     this.AUTH_SERVER_ADDRESS = GLOBAL_AUTH_SERVER_ADDRESS;
      //   }else{
          this.AUTH_SERVER_ADDRESS = GLOBAL_AUTH_SERVER_ADDRESS_SSL;
      //   }
      // });
    }
  get_tasks(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/tasks`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_active_tasks(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_active_task`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  /*get_active_routes(uid:number,profile_id:string,maintenance_team_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_active_routes?id=`+uid+"&profile_id="+profile_id+"&maintenance_team_id="+maintenance_team_id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }*/
  get_active_routes(partner_id:number,profile_id:string,maintenance_team_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/installation/get_active_routes?partner_id=`+partner_id+"&profile_id="+profile_id+"&maintenance_team_id="+maintenance_team_id).subscribe(data => {
        //console.table(data)
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_contract_info_from_maintenance_id(partner_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/installation/get_today_data?partner_id=${partner_id}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_my_installations(partner_id:string,maintenance_team_id:string,technician_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/installation/installation?partner_id=`+partner_id+"&maintenance_team_id="+maintenance_team_id +"&technician_id="+technician_id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_installations(partner_id){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/installation/installation?partner_id=${partner_id}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_installation_qr(barcodeData){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/installation/get_installation_qr?barcodeData=${barcodeData}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_last_data_id(central_id){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/installation/get_last_data_id?central_id=${central_id}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_sub_systems(installation_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_sub_systems/`+ installation_id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_installation_zone_location(installation_id){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_installation_zone_location?installation_id=`+installation_id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_supervisor_last_pos(group_id,maintenance_team_id){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_supervisor_last_pos?group_id=${group_id}&maintenance_team_id=${maintenance_team_id}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  set_tech_last_pos(uid,partner_id,route_scheduller_task_id,operation,write_date,google_lat,google_lng){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/set_tech_last_pos?uid=${uid}&partner_id=${partner_id}&route_scheduller_task_id=${route_scheduller_task_id}&operation=${operation}&write_date=${write_date}&google_lat=${google_lat}&google_lng=${google_lng}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_tech_last_pos(group_id,maintenance_team_id){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_tech_last_pos?group_id=${group_id}&maintenance_team_id=${maintenance_team_id}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  put_keep_alive_data(uid, session_uuid, imei, version, manufacturer, google_lat, google_lng, model, gps_timestamp, write_date,software_version){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/put_keep_alive_data?uid=${uid}&session_uuid=${session_uuid}&imei=${imei}&version=${version}&manufacturer=${manufacturer}&google_lat=${google_lat}&google_lng=${google_lng}&model=${model}&gps_timestamp=${gps_timestamp}&write_date=${write_date}&=software_version=${software_version}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }


  get_route_scheduller_task(group_id:string,route_scheduller_id:string,maintenance_team_id:string,technician_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_route_scheduller_task?&group_id=`+group_id+"&route_scheduller_id="+
      route_scheduller_id+"&maintenance_team_id="+maintenance_team_id+"&technician_id="+technician_id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_checklist(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_checklist`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_checklist_questions(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_checklist_questions`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_check_list_from_sub_system_id(sub_system_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_check_list_from_sub_system_id?&sub_system_id=`+sub_system_id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_zone_data_from_route_scheduller(route_scheduller_task_id:string){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_zone_data_from_route_scheduller?&route_scheduller_task_id=`+route_scheduller_task_id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_safetek_checklist_sections_resp_dump_a(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_safetek_checklist_sections_resp_dump_a`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  uploadFile(formData) {
    return this.httpClient.post(this.AUTH_SERVER_ADDRESS + '/upload', formData);
  }
  set_workguide_data(maintenance_request_id,file_name,observations,uid){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/set_workguide_data?&maintenance_request_id=${maintenance_request_id}&file_name=${file_name}&observations=${observations}&uid=${uid}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  set_signature_data(maintenance_request_id,file_name,rut,uid,sign_date,sign_name){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/set_signature_data?&maintenance_request_id=${maintenance_request_id}&file_name=${file_name}&rut=${rut}&uid=${uid}&sign_date=${sign_date}&sign_name=${sign_name}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  public put_uploadCentral(uid,partner_id,route_scheduller_task_id,maintenance_team_id,observations,data,photos_data) {
    return new Promise(resolve => {
      let post_data = {
        'uid': uid,
        'partner_id': partner_id,
        'route_scheduller_task_id' : route_scheduller_task_id,
        'maintenance_team_id' : maintenance_team_id,
        'observations' : observations,
        'data': data,
        'photos_data' : photos_data
      }
      this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/put_uploadCentral`,post_data).subscribe(data => {
        resolve(data);
        }, err => {
        console.log(err);
      });
    });
  }

  public put_gabinetes(uid,partner_id,route_scheduller_task_id,maintenance_team_id,observations,data,photos_data) {
    return new Promise(resolve => {
      let post_data = {
        'uid': uid,
        'partner_id': partner_id,
        'route_scheduller_task_id' : route_scheduller_task_id,
        'maintenance_team_id' : maintenance_team_id,
        'observations' : observations,
        'data': data,
        'photos_data' : photos_data
      }
      this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/put_gabinetes`,post_data).subscribe(data => {
        resolve(data);
        }, err => {
        console.log(err);
      });
    });
  }
  public put_ManifoldData(uid,partner_id,route_scheduller_task_id,maintenance_team_id,observations,data,photos_data) {
    return new Promise(resolve => {
      let post_data = {
        'uid': uid,
        'partner_id': partner_id,
        'route_scheduller_task_id' : route_scheduller_task_id,
        'maintenance_team_id' : maintenance_team_id,
        'observations' : observations,
        'data': data,
        'photos_data' : photos_data
      }
      this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/put_ManifoldData`,post_data).subscribe(data => {
        resolve(data);
        }, err => {
        console.log(err);
      });
    });
  }
  public put_PCdata(uid,partner_id,route_scheduller_task_id,maintenance_team_id,observations,data,photos_data) {
    return new Promise(resolve => {
      let post_data = {
        'uid': uid,
        'partner_id': partner_id,
        'route_scheduller_task_id' : route_scheduller_task_id,
        'maintenance_team_id' : maintenance_team_id,
        'observations' : observations,
        'data': data,
        'photos_data' : photos_data
      }
      this.httpClient.post(`${this.AUTH_SERVER_ADDRESS}/put_PCdata`,post_data).subscribe(data => {
        resolve(data);
        }, err => {
        console.log(err);
      });
    });
  }

  get_safetek_report_list(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/get_safetek_report_list`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_report_pdf(file_name){   
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/safetek_report_download?file_name=`+file_name).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  put_picture_data(file_name,route_scheduller_task_id,question_id){   
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/put_picture_data?file_name=${file_name}&route_scheduller_task_id=${route_scheduller_task_id}&question_id=${question_id}`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  public prepare_safetek_report(uid,partner_id,maintenance_team_id,maintenance_request_id) {
    return new Promise(resolve => {
      console.log('prepare_safetek_report/',uid,partner_id,maintenance_team_id,maintenance_request_id);
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/`+'prepare_safetek_report?uid='+uid+'&partner_id='+partner_id+
      '&maintenance_team_id='+maintenance_team_id+'&maintenance_request_id='+maintenance_request_id
      ).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  //get_check_list_from_sub_system_id
  // -> OTHER PROYECT

  get_task_types(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/task_types`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_task_satus(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/task_status`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  get_shifts(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/shifts`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_cuadrilles(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/quadrilles`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_equipment_types(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/equipment_types`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  get_equipment_user_types(){
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/equipment_user_types`).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  public setNotification(suit_src,suit_dst,google_lat_1,google_lat_2,google_long_1,google_long_2,distance) {
    return new Promise(resolve => {
      this.httpClient.get(`${this.AUTH_SERVER_ADDRESS}/`+'put_notifications?suit_src='+suit_src+"&suit_dst="+suit_dst+"&google_lat_1="+google_lat_1+"&google_lat_2="+google_lat_2+
      "&google_long_1="+google_long_1+"&google_long_2="+google_long_2+"&distance="+distance).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
}
