import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { startWith, take } from 'rxjs/operators';

@Component({
    selector: 'app-segment',
    templateUrl: 'segment.component.html',
    styleUrls: [
        'segment.component.scss'
    ]
})

export class SegmentComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() changed = new EventEmitter();
    @Input() segmentList = [];
    @Input() default = "";

    private subscriptions: Subscription[] = [];

    width = 50;
    selectedSegment: any;
    constructor(
        private cdr: ChangeDetectorRef,
        private elRef: ElementRef
    ) { }

    ngOnInit() { }

    ngAfterViewInit(){
        this.subscriptions.push(
            interval(200).pipe(
                startWith(0)
            ).subscribe(()=>{
                this.onResize(null);
                if(this.width>0){
                    this.subscriptions.forEach(sub=>sub.unsubscribe());
                }
            })
        )
        
    }

    ngOnDestroy(){
        this.subscriptions.forEach(sub=>sub.unsubscribe());
    }

    onResize(event) { 
        // this.width = 0;
        // this.cdr.detectChanges();
        // this.subscriptions.push(
        //     interval(100).pipe(
        //         take(1)
        //     ).subscribe(()=>{
                // let parentWidth = this.elRef.nativeElement.parentElement.clientWidth;
                let parentWidth = window.innerWidth;
                this.width = parentWidth - 30;
                console.log(`parent width: ${parentWidth} | width: ${this.width}`);
                this.cdr.detectChanges();        
        //     })
        // );		
	}

    segmentChanged(segment){
        this.selectedSegment = segment;
        this.changed.emit({detail: {value: segment}});
    }
}