import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_TWILIO_CALL_URL } from '../../core.global';
import { BasicService } from '../../_base/services/basic.service';

@Injectable()
export class TwilioCallService extends BasicService{
    baseUrl:string = '';
    
    constructor(protected http: HttpClient){
        super(http);
        this.baseUrl = API_TWILIO_CALL_URL;
    }
}