<apx-chart *ngIf="dataLoaded"
    [series]="apexLineChart.series"
    [chart]="{
        type: 'line',
        zoom: {
            enabled: false
        },
        toolbar: {
            show: false
        }
        }"
    [height]="500"
    [width]="getWidth()"
    [xaxis]="apexLineChart.xaxis"
    [stroke]="apexLineChart.stroke"
    [tooltip]="apexLineChart.tooltip"
    [colors]="apexLineChart.colors"
    [dataLabels]="apexLineChart.dataLabels"
    [grid]="apexLineChart.grid"
></apx-chart>