<div class="micontent">
  <div class="row predio-title-padding">
    <div class="col-sm-2">
      <div class="icono">
        <!-- <ion-icon type="ios" name="calendar"></ion-icon> -->
        <img src="assets/icons/icon-segmento-1.svg" alt="Predio" class="icon-predio-title">
      </div>
    </div>
    <div class="col-sm-10">
      <h2 class="text-left">Predio</h2>
      <span class="suit text-left">Parques Cordillera</span>
    </div>
  </div>
  
  <div class="row">
    <div class="col-sm-4">
      <div class="thoo"><span>Temperatura</span><span>31</span><img src="assets/icons/icon-segmento-2.svg" alt="Predio" class="icon-predio-segmento"></div>
    </div>
    <div class="col-sm-4">
      <div class="thoo"><span>Humedad</span><span>20%</span><img src="assets/icons/icon-segmento-3.svg" alt="Predio" class="icon-predio-segmento"></div>      
    </div>
    <div class="col-sm-4">
      <div class="thoo"><span>Velocidad viento</span><span>25</span><img src="assets/icons/icon-segmento-4.svg" alt="Predio" class="icon-predio-segmento"></div>
    </div>
    <div class="col-sm-4">
      <div class="thoo"><span>Dirección viento</span><span class="thoo-fix-title">Norte sur</span><img src="assets/icons/icon-segmento-5.svg" alt="Predio" class="icon-predio-segmento"></div>
    </div>
    <div class="col-sm-4">
      <div class="thoo"><span>Batería</span><span>30%</span><img src="assets/icons/icon-segmento-6.svg" alt="Predio" class="icon-predio-segmento"></div>
    </div>
    <div class="col-sm-4">
      <div class="thoo"><span>Estado</span><span class="thoo-fix-title">Normal</span><img src="assets/icons/icon-segmento-7.svg" alt="Predio" class="icon-predio-segmento"></div>
    </div>
    <!--<div class="col-sm-12">
      <div class="supervisor"><span>supervisor</span><span>Esteban Soto</span></div>
    </div>-->
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="predio-detail-map">
        <img src="assets/images/test_image.png">
      </div>
    </div>
  </div>
    
    <!-- 
      {{suit}}
      {{eco}}
      {{light}}
      {{date | date:'hh:mm d/M/yy'}}
      {{tvoc}}
    -->

        <!-- {{txtContent}} -->
      <!-- {{type}} -->
      <!-- {{active}} -->
</div>
<!-- <ion-footer> -->
  <button size="block" class="modalbtnclose" (click)="cerrar()">cerrar</button>
<!-- </ion-footer> -->
