
//FOR DESKTOP TESTING

export const on_line_enabled: boolean = true;
export const is_off_line_enabled:boolean = true;
//export const GLOBAL_AUTH_SERVER_ADDRESS_SSL:  string  =  'http://192.168.2.13:3000';                 //LOCA
//export const GLOBAL_AUTH_SERVER_ADDRESS:  string  =  'http://192.168.2.13:3000';                 //LOCA
// export const GLOBAL_AUTH_SERVER_ADDRESS:  string  =  'http://localhost:3000';                 //LOCA
// export const GLOBAL_AUTH_SERVER_ADDRESS_SSL:  string  =  'http://localhost:3000';                 //LOCA
// export const GLOBAL_AUTH_SERVER_ADDRESS:  string  =  'http://192.168.0.5:3000';                     //LOCA*/
export const GLOBAL_AUTH_SERVER_ADDRESS_SSL:  string  =  'https://siac.atcas.cl:45259';       //PROD
//export const GLOBAL_AUTH_SERVER_ADDRESS:  string  =  'http://satif.cl:45025';            //PROD
export const GLOBAL_AUTH_SERVER_ADDRESS:  string  =  'https://siac.atcas.cl:45259';       //PROD



//FOR PRODUCTION OR APPLICATION TESTING
//export const on_line_enabled: boolean = false;
//export const is_off_line_enabled:boolean = true;
//export const GLOBAL_AUTH_SERVER_ADDRESS_SSL:  string  =  'https://safetek.ir4hub.cl:45017';       //PROD
//export const GLOBAL_AUTH_SERVER_ADDRESS:  string  =  'http://safetek.ir4hub.cl:45016';            //PROD
//export const GLOBAL_AUTH_SERVER_ADDRESS_SSL:  string  =  'https://safetek.ir4hub.cl:45020';           //TEST//
//export const GLOBAL_AUTH_SERVER_ADDRESS:  string  =  'http://safetek.ir4hub.cl:45019';               //TEST


export const API_USERS_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/user`
export const API_GROUP_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/group`
export const API_ALARM_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/alarm`
export const API_SENSOR_DATA_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/device/sensor/data`
export const API_ALARM_STATUS_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/alarm/status`
export const API_TWILIO_CALL_STATUS_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/twilio/call/status`
export const API_TWILIO_CALL_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/twilio/call`
export const API_ALARM_INSTALLATION_REPORT_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/alarm/installation/report`
export const API_ALARM_INSTALLATION_DEVICE_REPORT_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/alarm/installation/device/report`
export const API_ALARM_ACTION_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/alarm/action`
export const API_COMMENT_TYPE_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/comment/type`
export const API_INSTALLATION_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/installation`
export const API_DEVICE_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/device`
export const API_DEVICE_TYPE_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/device/type`
export const API_SCHEDULED_JOB_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/scheduled-job`
export const API_SCHEDULED_JOB_TYPE_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/scheduled-job/type`
export const API_SCHEDULED_JOB_ACTION_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/scheduled-job/action`
export const API_SCHEDULED_JOB_WEEK_DAY_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/scheduled-job/week-day`
export const API_SCHEDULED_JOB_STATUS_URL = `${GLOBAL_AUTH_SERVER_ADDRESS}/scheduled-job/status`