import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { ModalController } from '@ionic/angular';
import { SensorDataService } from '../../../../core/device/services/sensor-data.service';
import { timestamp } from '../../../../core/_base/util/date.util';
import { formatTimestamp, yyyymmddhhmm } from '../../../../core/util/date.util';

@Component({
    selector: 'app-sensor-popover',
    templateUrl: 'sensor-popover.html',
    styleUrls: [
        './sensor-popover.scss'
    ]
})

export class SensorPopoverComponent implements OnInit {

    // @Input() options: {
    //     // sensor_id: number,
    //     device: number
    // };
    sensorList: any[] = [];
    @Input() device: any;

    constructor(
        // private modalController: ModalController,
        private sensorDataService: SensorDataService,
    ) { }

    ionViewDidEnter(){
        // this.sensorDataService.instant({}).subscribe((response)=>{
        //     console.log("response for instant")
        //     console.dir(response);
        // })
        this.getInstantData();
    }

    ngOnInit() { }

    getInstantData(){
        this.sensorDataService.instant({
            device_id: this.device.id
        })
            .subscribe((response:any)=>{
                console.log("response for instant")
                console.dir(response);
                const data = response.itemList;
                this.sensorList = data.map((row)=>{
                    return {
                        name: row.SensorType.name,
                        value: row.value,
                        date: yyyymmddhhmm(new Date(row.create_date))
                    }
                })
                // this.device = null;
                // if(data.length>0)
                //     this.device = data[0].Device;
            })
    }

    close(){
        // this.modalController.dismiss(null);
    }
}