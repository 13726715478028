<div class="container">
    <!-- <ion-button class="exit-btn" shape="round" (click)="close()">
        <ion-icon name="close"></ion-icon>
    </ion-button> -->
    <div class="other">
        <!-- <ion-label>Selecciona un dispositivo</ion-label>
        <ion-select class="btn-action" (ionChange)="selectDevice($event.target.value)">
            <ion-select-option  *ngFor="let device of location.Devices" [value]="device" >
              {{ device.description }}
            </ion-select-option>
        </ion-select> -->
        <h5 class="title" *ngIf="device">{{device.mac}}</h5>
        <h5 class="sub-title" *ngIf="device">{{device.description}}</h5>
        <ng-container *ngFor="let sensor of sensorList">
            <!-- <ion-grid> -->
                <div class="row">
                    <div class="col-sm-5">
                        <span 
                          color="medium" style="font-size: 1rem">{{sensor.date}}</span>
                        <!-- <ion-icon class="icono" name="locate"></ion-icon>                         -->
                    </div>
                    <div class="col-sm-4">
                        <span class="n1">{{sensor.name}}</span>
                    </div>
                    <div>
                        <span class="n2">{{sensor.value}}</span>                        
                    </div>
                    <div class="col-sm-1" *ngIf="sensor.value==1.0">
                        <!-- <ion-icon class="icono" name="locate"  style="display:flex"></ion-icon>                         -->
                    </div>
                </div>
            <!-- </ion-grid> -->
            <!-- <ion-icon class="icono" name="locate"></ion-icon> -->
        </ng-container>
        <!-- <ion-item lines="none">
            <ion-label class="n1">Fecha</ion-label>
            <ion-label class="n2">23/11/23</ion-label>
            <ion-icon class="icono" name="calendar"></ion-icon>
        </ion-item>
        <ion-item lines="none">
            <ion-label class="n1">Duración</ion-label>
            <ion-label class="n2">25:20</ion-label>
            <ion-icon class="icono" name="time"></ion-icon>
        </ion-item>         -->
    </div>
</div>