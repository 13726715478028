import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ApexPlotOptions,
  ApexLegend,
  ChartComponent,
  ApexNoData
} from "ng-apexcharts";
import * as _ from 'lodash';

export type ChartOptions = {
  series: any;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
  noData: ApexNoData;
};

@Component({
  selector: 'app-custom-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class CustomTreeComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @Input() series: any;
  @Input() title: ApexTitleSubtitle;

  constructor() {
    this.chartOptions = {
      series: [
        {
          data: [
            {
              x: "Sin Datos",
              y: 1
            }
          ]
        }
      ],
      legend: {
        show: false
      },
      chart: {
        type: "treemap"
      },
      title: {
        text: "Distibuted Treemap (different color for each cell)",
        align: "center"
      },
      colors: [
        "#3B93A5",
        "#F7B844",
        "#ADD8C7",
        "#EC3C65",
        "#CDD7B6",
        "#C1F666",
        "#D43F97",
        "#1E5D8C",
        "#421243",
        "#7F94B0",
        "#EF6537",
        "#C0ADDB"
      ],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      },
      noData: {
        text: "No hay datos"
      }
    };
  }
  ngOnInit(): void {
    
  }

  hasData(){
    return !_.isEmpty(this.series);
  }
}
