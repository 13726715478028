const moment = require('moment');

const getDateRange = (firstDate, lastDate) => {
    if (moment(firstDate).isSame(moment(lastDate), 'day'))
      return [lastDate];
    let date = firstDate;
    const dates = [date];
    do {
      date = moment(date).add(1, 'day');
      dates.push(date.format('YYYY-MM-DD'));
    } while (moment(date).isBefore(lastDate));
    return dates;
};

function formatDatetime(date:Date){
    return `${formatDate(date)}T${formatTime(date)}`;
}

function formatTimestamp(date:Date){
    return `${formatDate(date)} ${formatTime(date)}`;
}

function yyyymmddhhmm(date: Date){
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    return `${formatDate(date)} ${hours}:${minutes}`;
}

function hhmm(date: Date){
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    return `${hours}:${minutes}`;
}

function formatDate(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    return `${year}-${month}-${day}`;
}

function numberTo2DigitsString(number){
    return `${number<10?0:''}${number}`;
}

function formatTime(date: Date){
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    const seconds = numberTo2DigitsString(date.getSeconds());
    return `${hours}:${minutes}:${seconds}`;
}

function sameMonth(date1: Date, date2: Date){
    return date1.getFullYear() == date2.getFullYear() &&
        date1.getMonth() == date2.getMonth()
}

function sameDay(date1: Date, date2: Date){
    return sameMonth(date1, date2) &&
        date1.getDate() == date2.getDate()
}

function timestr(hour, minute, second){
    const twoDigits = numberTo2DigitsString;
    return `${twoDigits(hour)}:${twoDigits(minute)}:${twoDigits(second)}`;
}
export {formatDatetime, formatDate, formatTimestamp, formatTime, sameMonth, sameDay, yyyymmddhhmm, getDateRange, timestr, hhmm};