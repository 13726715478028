import { Injectable } from '@angular/core';
import { SafetekInstallation } from '../_models/installationData';
import { FileUpload } from '../_models/FileUpload';
import { SignatureData} from '../_models/signatureData'
import { UserGPSData } from '../_models/userGPS'
import { PictureType } from '../_models/PictureType'
import {WorkGuide } from '../_models/signatureData';
@Injectable({
  providedIn: 'root'
})

export class DataService {

  
  c_gps_data : UserGPSData;
  picture_type : PictureType;
  
  installationData : SafetekInstallation;    
  
  //wordguide : FileUpload;
  workguide : WorkGuide;
  signatureData : SignatureData;

  picture : FileUpload;
  pictureDatas = [];

  installationDatas = [];
  wordguides = [];

  //Used by multiple modules
  gps_data = [];
  is_on_line;

  
  constructor() { }
  setInstallationData(data){
    this.installationData = data;
    if (this.installationDatas){
      //FIND
      //var c_response = this.responses.find( x => x.question_id == id );
      var tmp = this.installationDatas.find(x => x.maintenance_request_id == this.installationData.maintenance_request_id);
      if (tmp){
        tmp.installationData = this.installationData        
      } else {
        this.installationDatas.push({
          maintenance_request_id : this.installationData.maintenance_request_id,
          installationData : this.installationData
        })  
      } 
    }else{
      this.installationDatas.push({
        maintenance_request_id : this.installationData.maintenance_request_id,
        installationData : this.installationData
      })
    }
  }
  getInstallationData(){
    return this.installationData;
  }
  setWorguide(data,maintenance_request_id){    
    //this.wordguide = data;
    var date = new Date();
    var formated_date = new Date(Date.UTC(date.getFullYear(), 
    date.getMonth(), date.getDate(), 
    date.getHours(), date.getMinutes(), 
    date.getSeconds())).toISOString();
    if (this.wordguides){
      var tmp = this.wordguides.find(x => x.maintenance_request_id == maintenance_request_id);
      if(tmp){
        tmp.wordguide = data;
        tmp.upload = false;
        tmp.date = formated_date;
      }else{
        this.wordguides.push({
          maintenance_request_id : maintenance_request_id,
          wordguide : data,
          upload : false,
          date :formated_date
        })
      }
    }else{
      this.wordguides.push({
        maintenance_request_id : maintenance_request_id,
        wordguide : data,
        upload : false,
        date : formated_date
      })
    }
  }
  getWorguide(maintenance_request_id){
    //console.log('Entering here getWorguide');
    //console.table(this.wordguides);
    if (this.wordguides){
      return this.wordguides.find(x => x.maintenance_request_id == maintenance_request_id);
    }else
      return undefined;
  }
  setPictures(data,maintenance_request_id,route_scheduller_task_id,question_id){
    console.log('Entering setPictures ' + route_scheduller_task_id + ' '+question_id);
    //console.log(JSON.stringify(data));
    //console.log(JSON.stringify(this.pictureDatas))
    var date = new Date();
    var formated_date = new Date(Date.UTC(date.getFullYear(), 
    date.getMonth(), date.getDate(), 
    date.getHours(), date.getMinutes(), 
    date.getSeconds())).toISOString();
    console.table('CHECKING PICTURE DATAS: '+JSON.stringify(this.pictureDatas))
    if (this.pictureDatas.length > 0){
      var tmp = this.pictureDatas.find(x => x.maintenance_request_id == maintenance_request_id);
      if(tmp){
        var tmp2 = tmp.pictures.find(x => x.question_id == question_id);
        if (tmp2){
          tmp.picture = data;
          tmp.date = formated_date;
          tmp.question_id = question_id;
          tmp.route_scheduller_task_id = route_scheduller_task_id;
        }else{
          tmp.pictures.push({
            picture : data,
            date : formated_date,
            question_id : question_id,
            route_scheduller_task_id : route_scheduller_task_id,
          })
        }
      }else{
        this.pictureDatas.push({
          maintenance_request_id : maintenance_request_id,
          pictures :[
            {              
              picture : data,
              date : formated_date,
              question_id : question_id,
              route_scheduller_task_id : route_scheduller_task_id,
            }
          ]          
        })
      }
    }else{
      this.pictureDatas.push(
        {
          maintenance_request_id : maintenance_request_id,
          pictures :[
            {
              route_scheduller_task_id : route_scheduller_task_id,
              picture : data,
              date : formated_date,
              question_id : question_id
            }
          ] 
        },
      )
    }
  }
  getPictures(){
    return this.pictureDatas;
  }
  setSignature(data){
    this.signatureData = data;
  }
  getSignature(){
    return this.signatureData;
  }
  get_lastGpsData(){    
    this.gps_data.sort((a, b) => {
      if(a.date < b.date) { return -1; }
      if(a.date > b.date) { return 1; }
      return 0;        
    });
    this.gps_data.forEach(element => {
      if (element.gps_data.gps_lat != '0.0'){
        return this.c_gps_data = element.gps_data         
      }        
    });
    return this.c_gps_data;
    
  }
  set_gpsData(data){
    this.gps_data.push({
        gps_data : data,
        date : new Date()
      }
    )
  }
  set_is_on_line(data){
    this.is_on_line =  data;
  }
  get_is_on_line(){
    return this.is_on_line;
  }
  set_pictureType(data:PictureType){
    this.picture_type = data;
  }
  get_pictureType(){
    return this.picture_type;
  }
}
