import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { ChartDataset } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import {DataService} from '../../../core/auth/_services/data.service';
import { ApiProviderService } from '../../../core/auth/_services/api-provider.service';

@Component({
  selector: 'app-statif-chart',
  templateUrl: './statif-chart.component.html',
  styleUrls: ['./statif-chart.component.scss'],
})
export class StatifChartComponent implements OnInit, OnChanges{
  @Input() data = [];
  @Input() title = "";
  @Input() threshold = 0;
  @Input() label = "";

  lineChartData: any[] = [
    { 
      data : [],
      label: 'Temperaturas',
      borderWidth : 1,
      borderJoinStyle : 'round',
      backgroundColor: '#FF6384',
      pointStyle : 'circle',
      pointBackgroundColor : 'rgba(0,0,0,0.1)',
      radius : 0.1,
      spanGaps : true
    },
    { 
      data : [],
      label: 'Umbral',
      borderWidth : 1,
      borderJoinStyle : 'round',
      backgroundColor: 'red',
      pointStyle : 'circle',
      pointBackgroundColor : 'rgba(0,0,0,0.1)',
      radius : 0.1,
      spanGaps : true
    },
  ];

  lineChartLabels: any[] = [];

  lineChartOptions = {
    scaleShowVerticalLines: true,
   showScale: true,
     animation: true,
     scaledisplay:true,
     responsive: true,
     
     scales: {
      // xAxis: {
      //   // The axis for this scale is determined from the first letter of the id as `'x'`
      //   // It is recommended to specify `position` and / or `axis` explicitly.
      //   type: 'time',
      // }
      y: {
        suggestedMin: 0,
        suggestedMax: 2
      }
    }
      // scales: {
        
      //   x: [          
      //     {
      //         display: true,                                              
      //     },
          
      //   ],
      //   y: [
      //     {
      //       // display: true,
      //       ticks: {
      //         beginAtZero: true
      //       }
      //     }
      //   ],

        
      // }
  };

  lineChartColors: any[] = [
    {
      borderColor: '#cc1414',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  ];

  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  constructor() { 
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.lineChartData[0].data = [];
    this.lineChartData[1].data = [];
    this.lineChartLabels = [];
    this.data.forEach((row:any)=>{
      this.lineChartData[0].data.push(row.value);
      this.lineChartLabels.push(row.label);
      this.lineChartData[1].data.push(this.threshold);
    })
    this.lineChartData[0].label = this.label;
    console.log("changes");
    console.log(this.data);
    console.dir(this.lineChartData);
    console.dir(this.lineChartLabels);
  }
  graphs_data;
  ngOnInit() {
    
    // const startingDate = new Date();
    // startingDate.setDate(startingDate.getDate()-1);
    // const cursorDate = new Date(startingDate);
    // const now = new Date();
    // while(cursorDate.getTime()<now.getTime()){
    //   this.lineChartData[0].data.push(Math.random()*1000);
    //   this.lineChartLabels.push(cursorDate.toLocaleTimeString());
    //   cursorDate.setMinutes(cursorDate.getMinutes()+10);
    // }
  }
  
}
