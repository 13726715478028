<!-- Responsive Datatable -->
<!-- <section id="ngx-datatable-Responsive"> -->

    <!-- ngx-datatables responsive -->
    <ngx-datatable
        #table
        class="bootstrap core-bootstrap"
        [columnMode]="ColumnMode.force"
        [headerHeight]="40"
        [rowHeight]="58"
        [footerHeight]="50"
        [reorderable]="false"
        rowHeight="auto"
        [limit]="limit"
        [rows]="rows"
        [scrollbarH]="true"
        (sort)="sortEvent($event)"
        (scroll)="handleScroll()"
        [virtualization]="false"
        [externalSorting]="rows.length<=limit"
        [count]="totalCount" 
        [offset]="0"
        [externalPaging]="rows.length<=limit"
        (page)='rows.length<=limit?pageEvent($event):null'
    >
        <ngx-datatable-column name="{{col.alias}}" prop="{{col.name}}" [width]="col.width" *ngFor="let col of columnList">
        <ng-template ngx-datatable-cell-template let-row="row">
            <div [ngSwitch]="col.dtype">
            <div *ngSwitchCase="'date'">{{(col.transformFcn?col.transformFcn(row):row[col.name])}}</div>
            <div class="wrapword" [ngClass]="{
                'bg-light-primary': col.level && col.level(row) == 'primary',
                'bg-light-success': col.level && col.level(row) == 'success',
                'bg-light-danger': col.level && col.level(row) == 'danger',
                'bg-light-warning': col.level && col.level(row) == 'warning',
                'bg-light-info': col.level && col.level(row) == 'info',
                'badge badge-pill': col.level
              }" *ngSwitchDefault>{{col.transformFcn?col.transformFcn(row):row[col.name]}}</div>
            </div>
        </ng-template>
        </ngx-datatable-column>
        
        <!-- <ngx-datatable-column name="Action" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
                <div class="action-column-fix">
                <button name="edit" class="btn-icon" (click)="onClickActionBtn(row, 'edit')" *ngIf="availableActions['edit']"><i data-feather="edit" class="text-primary cursor-pointer"></i></button>
                <button name="delete" class="btn-icon" (click)="onClickActionBtn(row, 'delete')" *ngIf="availableActions['delete']"><i data-feather="trash-2" class="text-primary cursor-pointer"></i></button>
                <button name="{{action.action}}" class="btn-icon" (click)="onClickActionBtn(row, action.action)" *ngFor="let action of actionBtnList">
                    <i [data-feather]="action.icon" class="text-primary cursor-pointer"></i>
                </button>
                </div>
            </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column name="Actions" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center">
                <div ngbDropdown container="body">
                  <a
                    ngbDropdownToggle
                    href="javascript:void(0);"
                    class="hide-arrow"
                    id="dropdownBrowserState"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                    <a href="javascript:void(0)" (click)="onClickActionBtn(row, btn.name)" ngbDropdownItem *ngFor="let btn of actionBtnList" class="d-flex align-items-center"
                      ><i data-feather="file-text" class="mr-50"></i> {{btn.text}}</a
                    >
                    <a href="javascript:void(0)" (click)="onClickActionBtn(row, 'edit')" ngbDropdownItem class="d-flex align-items-center"
                      ><i data-feather="file-text" class="mr-50"></i> Editar</a
                    >
                    <a href="javascript:void(0)" (click)="onClickActionBtn(row, 'delete')" ngbDropdownItem class="d-flex align-items-center"
                      ><i data-feather="archive" class="mr-50"></i> Eliminar</a
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

        <!-- <ngx-datatable-footer>
            <ng-template 
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
              let-isVisible="isVisible">
                <div class="page-count">
                  <span *ngIf="selectedMessage">
                    {{selectedCount.toLocaleString()}} {{selectedMessage}} / 
                  </span>
                  {{rowCount.toLocaleString()}} {{totalMessage}}
                </div>
                <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    [hidden]="!((rowCount / pageSize) > 1)"
                    (change)="table.onFooterPage($event)">
                </datatable-pager>
            </ng-template>
        </ngx-datatable-footer> -->
    </ngx-datatable>
    <!--/ ngx-datatables responsive -->        
<!-- </section> -->