import { Component, Input, OnInit } from '@angular/core';
import { PieData } from '../../charts/detail-pie/detail-pie.component';

@Component({
  selector: 'app-detail-pie-card',
  templateUrl: './detail-pie-card.component.html',
  styleUrls: ['./detail-pie-card.component.scss']
})
export class DetailPieCardComponent implements OnInit {
  @Input() title: string;
  @Input() data: PieData;
  @Input() unit: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
