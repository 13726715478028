import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { VariableHistoryPage } from './variable-history.page' ;
import { StatifChartComponent } from './statif-chart/statif-chart.component';
import { ChartsModule  } from 'ng2-charts';
import { SegmentComponent } from './segment/segment.component';
const routes: Routes = [
  {
    path: '',
    component: VariableHistoryPage
  }
];

@NgModule({
    declarations: [
        VariableHistoryPage,
        StatifChartComponent,
        SegmentComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ChartsModule,
        // RouterModule.forChild(routes),
    ],
    exports: [
        VariableHistoryPage
    ]
})


export class VariableHistoryModule { }
