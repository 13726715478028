<!--The content below is only a placeholder and can be replaced.-->
<div id="chart">
    <apx-chart
      [series]="hasData()?series:chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [title]="title"
      [colors]="chartOptions.colors"
      [noData]="chartOptions.noData"
    ></apx-chart>
</div>