import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ALARM_URL } from '../../core.global';
import { BasicService } from '../../_base/services/basic.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AlarmService extends BasicService{
    baseUrl:string = '';

    alarms$ = new BehaviorSubject(null);
    
    constructor(protected http: HttpClient){
        super(http);
        this.baseUrl = API_ALARM_URL;
    }

    takeDecision(alarm, alarmAction, comment, commentType): Observable<any>{
        return this.http.put(`${this.baseUrl}/decision/${alarm.id}`, {
            id: alarm.id, 
            alarm_action_id: alarmAction.id,
            user_comment: comment,
            comment_type_id: commentType.id
        })
    }

    setAlarms(alarms){
        this.alarms$.next(alarms);
    }
}