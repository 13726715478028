<!--The content below is only a placeholder and can be replaced.-->
<div id="chart">
    <apx-chart
      [series]="series"
      [chart]="chartOptions.chart"
      [labels]="labels"
      [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke"
      [responsive]="chartOptions.responsive"
      [title]="title"
      [legend]="chartOptions.legend"
    ></apx-chart>
  </div>
  