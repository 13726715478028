// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'http://localhost:4000',
  firebase: {
    apiKey: "AIzaSyBI6kaG147IjE6OJpsDTPYgLUAGBe59-m8",
    authDomain: "siac-c299f.firebaseapp.com",
    projectId: "siac-c299f",
    storageBucket: "siac-c299f.appspot.com",
    messagingSenderId: "873183231278",
    appId: "1:873183231278:web:897080b9bf3236040a4ebf",
    measurementId: "G-DYP5RR04YS"
  },
  token: 'njNDN2hNwt2Swcj2',
  user: 'WC9sgkqyX6U5brew',
  lastLoginDate: 'HCgWWYMekQZh7Yy2',
  rememberLastLogin: 'rNsFQ7fVvySBGU7d',
  company: 'qN31Q7fZzySXfU7d',
  plant: 'Pc31y7f5BTeK6U7z',
  login: 'Acw85ccpB5ed2rGs',
  mapMode: 'eZw25cyy1INmv2Tp'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
