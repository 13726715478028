
function formatDate(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    const seconds = numberTo2DigitsString(date.getSeconds());
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function timestamp(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    const seconds = numberTo2DigitsString(date.getSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function formatJustDate(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    return `${year}-${month}-${day}`;
}

function numberTo2DigitsString(number){
    return `${number<10?0:''}${number}`;
}

export {formatDate, formatJustDate, timestamp};