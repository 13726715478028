<div class="container">

    <div class="other">
        <!-- <ion-label>Selecciona un dispositivo</ion-label>
        <ion-select class="btn-action" (ionChange)="selectDevice($event.target.value)">
            <ion-select-option  *ngFor="let device of location.Devices" [value]="device" >
              {{ device.description }}
            </ion-select-option>
        </ion-select> -->
        <h5 class="title" *ngIf="selectedDevice">{{selectedDevice.mac}}</h5>
        <h5 class="sub-title" *ngIf="selectedDevice">{{selectedDevice.description}}</h5>
        <!-- <ion-item lines="none" *ngFor="let sensor of sensorList"> -->
            
            
                <div class="row" *ngFor="let sensor of sensorList">
                    <div class="col-sm-5">
                        <span color="medium" style="font-size: 1rem">{{sensor.ts}}</span>
                    </div>
                    <div class="col-sm-4">
                        <span class="n1">{{sensor.name}}</span>
                    </div>
                    <div>
                        <span class="n2">{{sensor.value}}</span>                        
                    </div>
                    <div class="col-sm-1" *ngIf="sensor.value==1.0">
                        <!-- <ion-icon class="icono" name="locate" style="display:flex"></ion-icon>-->
                    </div>
                </div>
            
        <!-- </ion-item> -->
        <h5 *ngIf="loading">Cargando...</h5>
        <h5 *ngIf="!loading && (sensorList == null || sensorList.length == 0)">
            No hay datos
        </h5>
        <!-- <ion-item lines="none">
            <ion-label class="n1">Fecha</ion-label>
            <ion-label class="n2">23/11/23</ion-label>
            <ion-icon class="icono" name="calendar"></ion-icon>
        </ion-item>
        <ion-item lines="none">
            <ion-label class="n1">Duración</ion-label>
            <ion-label class="n2">25:20</ion-label>
            <ion-icon class="icono" name="time"></ion-icon>
        </ion-item>         -->
    </div>
</div>