import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_USERS_URL } from '../../core.global';
import { BasicService } from '../../_base/services/basic.service';

@Injectable()
export class UserService extends BasicService{
    baseUrl:string = '';
    
    constructor(protected http: HttpClient){        
        super(http);
        this.baseUrl = API_USERS_URL;        
    }

    login(user:any){
        console.log(`${this.baseUrl}/login`)
        return this.http.post(`${this.baseUrl}/login`, user)
        // .pipe(
            // tap((response: any)=>{
            //     if(response.status && response.status=='success'){
            //         this.setUser(response.rows[0]);
            //         this.setToken(response.token);
            //         this.setLastLoginDate(new Date())
            //     }
            // })
        // )
    }

    updateToken(token){
        console.log(`${this.baseUrl}/token`)
        return this.http.put(`${this.baseUrl}/token`, {token})
    }

    getLastLogin(){
        return this.http.get(`${this.baseUrl}/last-login`)
    }

    forgetLastLogin(){
        return this.http.get(`${this.baseUrl}/last-login/forget`)
    }

    changePassword(login, password, newPassword){
        return this.http.put(`${this.baseUrl}/password/change`, {
            login, password, newPassword
        })
    }

    resetPassword(email){
        return this.http.put(`${this.baseUrl}/password/reset`, {
            email
        })
    }
}