import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
// import { QueryParamsModel } from '../../../../core/_base/crud';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss',

  ]
})
export class BasicTableComponent implements OnInit, OnDestroy, AfterViewInit {

  public ColumnMode = ColumnMode;
  // @ViewChild(DatatableComponent) table: DatatableComponent;
  tablestyle = 'bootstrap';
  rows = [];
  fullScreenRow = [];
  loadingIndicator = true;
  reorderable = true;

  isLoading: boolean;
  page: any;

  private subscriptions: Subscription[] = [];

  @Input() columnList:{alias:string, name:string, isDate?:boolean, transfromFcn?:any, dtype?:string}[] = [];
  @Input() actionBtnList: any[] = [];
  @Input() rowsObs: Subject <any[]>;
  @Input() limit: number = 6;
  @Input() totalCount: number = 6;
  @Input() shouldHideActionBtn = null;
  @Input() availableActions: {edit: boolean, delete: boolean} = {edit: true, delete: true};
  @Output() getRowsEvent = new EventEmitter<any>()
  @Output() clickActionBtnEvent = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();
  pageList: number[] = [];
  currentPage: number = 1;
  lastPage:number = 1;
  currentNavPag: number = 1;


  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
      this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  ngOnInit() {
    console.log('this.currentNavPag init >>>', this.currentNavPag);
  }

  ngAfterViewInit(){
    this.subscriptions.push(
        this.rowsObs.subscribe(rows=>{
          console.log("rows: ");
          console.dir(rows);
            this.rows = rows;
            this.refreshPagination();
            this.cdr.detectChanges();
            this.isLoading = false;
        })
    )
    this.requestItems(this.currentPage);
  }

  handleScroll(){

  }
  
  getNotHiddenActionBtnList(row){
    if(this.shouldHideActionBtn==null || this.shouldHideActionBtn == undefined){
      return this.actionBtnList;
    }
    return this.actionBtnList.filter(btn=>!this.shouldHideActionBtn(btn.text, row));
  }

  refreshPagination(){
    this.pageList = [];
    let startPage = this.currentPage-3;
    if(startPage <= 0) startPage = 1;
    let endPage = startPage + 6;
    if(endPage > this.totalCount/this.limit) endPage = Math.ceil(this.totalCount/this.limit)

    console.log(`start page: ${startPage} | end page: ${endPage} | total: ${this.totalCount} | limit: ${this.limit}`);
    for(let i = startPage;i<=endPage;i+=1){
      this.pageList.push(i);
    }

    this.lastPage = Math.ceil(this.totalCount/this.limit);
  }

  requestItems(selectedPage){
    this.isLoading = true;
    this.clearTable();
    const request = {page: selectedPage, pageSize: this.limit};
    console.log("request: ");console.dir(request);
    this.getRowsEvent.emit(request);
    this.currentPage = selectedPage;
    this.currentNavPag = selectedPage;
    this.refreshPagination();
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  // setPage(pageInfo) {
  //   this.loadItems(pageInfo);
  // }

  onClickActionBtn(row, action){
    console.dir({action, row})
    this.clickActionBtnEvent.emit({
      row: row,
      action: action
    });
  }

  clickPaginationBtn(selectedPage){
    console.log('pagination button clicked');
    console.dir(selectedPage);
    if(selectedPage == 'next'){
      this.requestItems(this.currentPage+1);
    }else if(selectedPage == 'prev'){
      this.requestItems(this.currentPage-1);
    }else{
      this.requestItems(selectedPage);
    }
  }

  sortEvent(event){
    this.isLoading = true;
    this.clearTable();
    this.onSort.emit(event);
  }

  pageEvent(event){
    const offset = event.offset; // == pagenumber
    this.requestItems(offset+1);
  }

  clearTable(){
    this.rows = [];
    this.cdr.detectChanges();
  }
}
