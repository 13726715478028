import { Component, Inject, OnDestroy, OnInit, ElementRef, Renderer2, inject, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { BehaviorSubject, Subject, Subscription, combineLatest, interval, of } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as Waves from 'node-waves';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { CoreTranslationService } from '@core/services/translation.service';
import { Messaging, getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import { menu } from 'app/menu/menu';
import { locale as menuEnglish } from 'app/menu/i18n/en';
import { locale as menuFrench } from 'app/menu/i18n/fr';
import { locale as menuGerman } from 'app/menu/i18n/de';
import { locale as menuPortuguese } from 'app/menu/i18n/pt';
import { AuthService } from './core/auth/services/auth.service';
import { Router } from '@angular/router';
import { AlarmService } from './core/alarm/services/alarm.service';
import { AlarmActionService } from './core/alarm/services/alarm-action.service';
import { UserService } from './core/auth/services/user.service';
import { NotificationsService } from './layout/components/navbar/navbar-notification/notifications.service';
import * as _ from 'lodash';
import { ToastService } from './main/components/toasts/toasts.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;
  defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'en'; // Set application default language i.e fr

  // Private
  private _unsubscribeAll: Subject<any>;
  subscriptions: Subscription[] = [];

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {CoreTranslationService} _coreTranslationService
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private alarmService: AlarmService,
    private alarmActionService: AlarmActionService,
    private userService: UserService,
    private notificationService: NotificationsService,
    public toastService: ToastService
  ) {
    // Get the application main menu
    this.menu = menu;

    // Register the menu to the menu service
    this._coreMenuService.register('main', this.menu);

    // Set the main menu as our current menu
    this._coreMenuService.setCurrentMenu('main');

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'fr', 'de', 'pt']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang('en');

    // Set the translations for the menu
    this._coreTranslationService.translate(menuEnglish, menuFrench, menuGerman, menuPortuguese);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Init wave effect (Ripple effect)
    Waves.init();

    this.listenToAlarms();

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      console.log("core config"); console.dir(config);

      // Set application default language.

      // Change application language? Read the ngxTranslate Fix

      // ? Use app-config.ts file to set default language
      const appLanguage = this.coreConfig.app.appLanguage || 'en';
      this._translateService.use(appLanguage);

      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/

      // Set the default language to 'en' and then back to 'fr'.

      setTimeout(() => {
        this._translateService.setDefaultLang('en');
        this._translateService.setDefaultLang(appLanguage);
      });

      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  listenToAlarms(){
    this.authService.authenticated$.pipe(
      tap((loggedIn)=>console.log(`logged ? ${loggedIn}`)),
      filter(loggedIn=>loggedIn != false)
    ).subscribe(()=>{
      interval(1000*60).pipe(
        switchMap(()=>of(this.authService.isAuthenticated()))
      ).subscribe((authenticated)=>{
        if(authenticated == false)this.authService.authenticated$.next(false);
      })
      this.authService.authenticated$.subscribe(logged=>{
        if(!logged){
          this.authService.signOut();
        }
      })
    })
    
    this.authService.authenticated$.pipe(
      tap((loggedIn)=>console.log(`logged ? ${loggedIn}`)),
      filter(loggedIn=>loggedIn != false),
      tap(()=>{
        this.router.navigate(['dashboard']);
      })
    ).subscribe(()=>{
      // this.platform.ready().then(() => {
        this.subscriptions.push(
          interval(60*1000).pipe(
            startWith(0),
            filter(()=>this.authService.isAuthenticated())
          ).subscribe(()=>{
            console.log("1 minute cycle fetching alarms...")
            this.fetchAlarms();
          })
        )
        this.setupFCM();        
    })
  }

  fetchAlarms(){
    console.log("fetch alarms called")
    combineLatest([
      this.alarmService.getPage({
      sortField: "id",
      sortOrder: "desc",
      pageSize: 0,
      pageNumber: 0,
      filter: {
        alarm_status_id: 1,
        status_id: 1
      }
    } as any).pipe(
      tap((response)=>console.dir(response)),
      filter((response:any)=>response && response.items),
      map((response)=>response.items)
    ),
    this.alarmActionService.getAll().pipe(
      tap((alarmActionList)=>console.dir(alarmActionList)),
      filter((alarmActionList:any)=>alarmActionList && alarmActionList.length>0)
    )
  ]).pipe( 
    tap(([alarmList, alarmActionList])=>{
    console.log("alarma y acciones")
    console.dir({alarmList, alarmActionList})
  }),
    filter(([alarmList, alarmActionList])=>alarmList && alarmList.length>0 && alarmActionList && alarmActionList.length>0)
  ).subscribe(([alarmList, alarmActionList])=>{
    alarmList = alarmList.sort(function compare(a, b) {
      var dateA = new Date(a.create_date);
      var dateB = new Date(b.create_date);
      return dateA.getTime() - dateB.getTime();
    });
    // let latestAlarm = alarmList[0];
    // alarmList.forEach(alarm=>{
    //   const prevTs = new Date(latestAlarm.create_date).getTime();
    //   const ts = new Date(alarm.create_date).getTime();
    //   if(prevTs < ts){
    //     latestAlarm = alarm;
    //   }
    // })
    // console.log("latest alarm: ");console.dir(latestAlarm);
    // alarmList = alarmList.slice(-3);
    // console.log("going to open alarm modal with alarm list");console.dir(alarmList);

    const todayDate = new Date();
    todayDate.setHours(0);
    todayDate.setMinutes(0);
    todayDate.setSeconds(0);
    const todayAlarms = alarmList.filter(alarm=>{
      const alarmDate = new Date(alarm.create_date);
      return alarmDate.getTime()>=todayDate.getTime()
    })
    this.alarmService.setAlarms(todayAlarms);
    console.log("alarmas de hoy: ");console.dir(todayAlarms)
    if(todayAlarms.length>0)
      this.openAlarmModal(todayAlarms.slice(-3), alarmActionList)
    
    // this.toastService.show(todayAlarms[0].alarm_message, {autohide: true, classname: 'toast'})
    
  })
  }

  async openAlarmModal(alarmList, alarmActionList){
    console.log("open alarm modal")
    const systemMessages = alarmList.map(alarm=>{
      return {
        icon: 'alert-triangle',
        heading: `<span class="font-weight-bolder">Alarma</span> activada en ${_.get(alarm, 'Device.Location.name', '')}`,
        text: alarm.alarm_message
      }
    })
    this.notificationService.onApiDataChange.next({
      systemMessages: systemMessages,
      system: true
    })
    // const modal = await this.modalController.create({
    //   component:  AlarmModal,
    //   cssClass: 'alert-modal',
    //   componentProps: {
    //     alarmList,
    //     alarmActionList
    //   },
    // });
    // return await modal.present();
  }

  private readonly _messaging = inject(Messaging);
  private readonly _message = new BehaviorSubject<unknown | undefined>(undefined);
  async registerNotifications (){

    // Get the current FCM token
    getToken(this._messaging)
      .then((token) => {
        console.log('Token', token);
        this.userService.updateToken(token).subscribe(resp=>{
          console.log("update token resp: ");console.dir(resp);
        });
        // You can send this token to your server and store it there
        // You can also use this token to subscribe to topics
      })
      .catch((error) => console.log('Token error', error));

    // Listen for messages from FCM
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      Swal.fire({
        title: "Alerta",
        text: payload.notification.body,
        icon: "warning"
      });
      // ...
    });
    onMessage(this._messaging, {
      next: (payload) => {
        console.log('Message', payload);
        Swal.fire({
          title: "Alerta",
          text: payload.notification.body,
          icon: "warning"
        });
        // this.toastService.show(payload.notification.body, {autohide: true, classname: 'toast'})
        // You can display the message or do something else with it
      },
      error: (error) => console.log('Message error', error),
      complete: () => console.log('Done listening to messages')
    });
    // let permStatus = await PushNotifications.checkPermissions();
  
    // if (permStatus.receive === 'prompt') {
    //   permStatus = await PushNotifications.requestPermissions();
    // }
  
    // if (permStatus.receive !== 'granted') {
    //   throw new Error('User denied permissions!');
    // }
  
    // await PushNotifications.register();

    // PushNotifications.addListener(
    //   'registration',
    //   (token: Token) => {
    //     console.log('My token: ' + JSON.stringify(token));
    //     this.token = token.value;
    //     this.userService.updateToken(this.token).subscribe(resp=>{
    //       console.log("update token resp: ");console.dir(resp);
    //     });
    //   }
    // );

    // PushNotifications.addListener('registrationError', (error: any) => {
    //   console.log('Error: ' + JSON.stringify(error));
    // });

    // PushNotifications.addListener(
    //   'pushNotificationReceived',
    //   async (notification: PushNotificationSchema) => {
    //     console.log("notification: ")
    //     console.dir(notification);
    //     const alert = await this.alertController.create({
    //       header: JSON.stringify(notification.title).substring(1, JSON.stringify(notification.title).length - 1),
    //       message: JSON.stringify(notification.body).substring(1, JSON.stringify(notification.body).length - 1),
    //       // buttons: [
    //       //   {
    //       //     text: 'OK',
    //       //     handler: () => {
    //       //       if (data.data.url != '' && data.data.url != undefined) {
    //       //         const browser = this.iab.create(data.data.url, '_blank', { location: 'no' });
    //       //       }
    //       //     }

    //       //   }
    //       // ]
    //     });
    //     await alert.present();
    //   }
    // );
    // PushNotifications.addListener(
    //   'pushNotificationActionPerformed',
    //   async (notification: ActionPerformed) => {
    //     const data = notification.notification.data;
    //     console.log('Action performed: ' + JSON.stringify(notification.notification));
    //     console.dir(notification);
    //   }
    // );
  }

  private async setupFCM() {
    this.registerNotifications();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this.subscriptions.forEach(sub=>sub.unsubscribe())
    this._unsubscribeAll.complete();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
