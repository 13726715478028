<!-- <ion-content> -->
  <div class="micontent">
    <h1 class="title-app">{{location.name}}</h1>
    <div class="row head-data-box">
      <div  class="col-sm-6">
        <h4>Tipo</h4>
        <h2>{{(device || location.Devices[0]).DeviceType.name}} </h2>
        <h2></h2>
      </div>
      <div  class="col-sm-6">
        <h4>Dispositivo</h4>
        <h2>{{(device || location.Devices[0]).description}}</h2>
      </div>
      <div  class="col-sm-6">
        <h4>Altura</h4>
        <h2>{{location.high_m}}m</h2>
      </div>
      <div  class="col-sm-6">
        <h4>Último dato</h4>
        <h3 style="margin:0px"><p style="margin:0px; font-size: 0.8rem">
          {{lastSensorData? (lastSensorData.create_date ) : 'No hay data'}}
        </p></h3>
      </div>
    
    </div>
   
    <div class="row chart-box">
        <app-segment class="alar" (changed)="segmentChanged($event)" [segmentList]="segmentList" [default]="'temperature'">
          
        </app-segment>
      <div  class="col-sm-12">
        <h3>{{chart?chart.title:''}}</h3>
      </div>

      <div  class="col-sm-12" *ngIf="chart">
        <app-statif-chart 
        [title]="chart.title"
        [data]="chart.data"
        [threshold]="chart.secureThreshold"
        [label]="chart.label">

        </app-statif-chart>
      </div>
    
    </div>
  </div>
  <!-- <ion-segment-button layout="icon-end">
    <ion-icon name="pin"></ion-icon>
    <ion-label>Map</ion-label>
  </ion-segment-button> -->
<!-- </ion-content> -->
<button class="col-sm-block" class="modalbtnclose" style="margin-top:auto;" (click)="goBack()">cerrar</button>