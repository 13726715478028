import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { ApexAxisChartSeries, ApexLegend, ApexTitleSubtitle, ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexStroke,
  ApexFill
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: 'app-custom-pie',
  templateUrl: './custom-pie.component.html',
  styleUrls: ['./custom-pie.component.scss']
})
export class CustomPieComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input() series: ApexNonAxisChartSeries = [];
  @Input() labels: any;
  @Input() title: ApexTitleSubtitle;

  constructor() {
    this.chartOptions = {
      series: [14, 23, 21, 17, 15, 10, 12, 17, 21],
      chart: {
        type: "donut"
      },
      stroke: {
        colors: ["#fff"]
      },
      fill: {
        opacity: 0.8
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      legend: {
        labels: {
          colors: ['#b4b7bd'],
          useSeriesColors: false
        }
      }
    };
  }
  ngOnInit(): void {
  }

}
