import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { AuthenticationService } from '../../../auth/service';
// import { select, Store } from '@ngrx/store';
// import { Observable } from 'rxjs';
// import { AppState } from '../../reducers';
// import { selectAuthState } from '../_selectors/auth.selectors';
// import { ModuleService } from '../../plant-configuration/_services/module.service';
@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(
    public router: Router, 
    public authenticationService: AuthenticationService
    // private store: Store<AppState>,
    // private moduleService: ModuleService
  ) {}
  
  // canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
  //   const groupIdList = route.data.groups;
  //   return this.store.pipe(
  //       select(selectAuthState),
  //       map((auth) => {
  //         console.log("route: ");console.dir(route);
  //           // console.log ("puede pasar: "+ auth.isUserLoaded && groupIdList.includes(auth.user.group_id));
  //           const authorized = auth.isUserLoaded && groupIdList.includes(auth.user.group_id);
  //           if(!authorized){
  //             this.router.navigate(['auth']);
  //           }
  //           return authorized;
  //       })
  //   );		
  // }
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authenticationService.currentUser.pipe(
      map(user=>{
        return route.data.roles.includes(user.role)
      })
    );
    // const moduleName = route.routeConfig.path;
    // return this.store.pipe(
    //     select(selectAuthState),
    //     map((auth) => {
    //         // console.log ("puede pasar: "+ auth.isUserLoaded && groupIdList.includes(auth.user.group_id));
            
    //         const moduleList = this.moduleService.moduleList;
    //         let authorized = false;
    //         if(moduleList != undefined){
    //           const modulePermissions = this.moduleService.mapToModuleGroupPermission(moduleList);
    //           const groupIdList = modulePermissions[moduleName];
    //           authorized = auth.isUserLoaded && groupIdList.includes(auth.user.group_id);
    //         }
            
    //         if(!authorized){
    //           this.router.navigate(['auth']);
    //         }
    //         return authorized;
    //     })
    // );		
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return of(true);
    // let path = state.url;
    // path = path.replace('/add', '');
    // path = path.replace('/list', '');
    // path = path.split('/edit')[0];
    // return this.store.pipe(
    //     select(selectAuthState),
    //     map((auth) => {
    //         // console.log ("puede pasar: "+ auth.isUserLoaded && groupIdList.includes(auth.user.group_id));
            
    //         const moduleList = this.moduleService.moduleList;
    //         let authorized = false;
    //         if(moduleList != undefined){
    //           const pathPermissions = this.moduleService.mapToPathGroupPermission(moduleList);
    //           const groupIdList = pathPermissions[path];
    //           // console.dir(childRoute);
    //           // console.dir(state);
    //           console.log(`looking for path ${path}`);
    //           console.dir(pathPermissions)
    //           authorized = auth.isUserLoaded && groupIdList.includes(auth.user.group_id);
    //         }
            
    //         if(!authorized){
    //           this.router.navigate(['auth']);
    //         }
    //         return authorized;
    //     })
    // );	
  }
}