import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CoreCommonModule } from '../../../@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { BasicTableComponent } from './tables/basic-table/basic-table.component';
import { CustomTreeComponent } from './charts/tree/tree.component';
import { CustomPieComponent } from './charts/custom-pie/custom-pie.component';
import { InstallationMapComponent } from './components/installation-map/installation-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CameraModule } from '../../components/camera-modal/camera-modal.module';
import { ComponenteModule } from '../../components/components.module';
import { VariableHistoryModule } from '../../components/variable-history/variable-history.module';
import { CustomLineComponent } from './charts/custom-line/custom-line.component';
import { ChartsModule } from 'ng2-charts';
import { StatsHorizontalCardComponent } from './cards/stats-horizontal-card/stats-horizontal-card.component';
import { ContentHeaderModule } from '../../layout/components/content-header/content-header.module';
import { DetailPieComponent } from './charts/detail-pie/detail-pie.component';
import { ChartjsModule } from '../../main/charts-and-maps/charts/chartjs/chartjs.module';
import { DetailPieCardComponent } from './cards/detail-pie-card/detail-pie-card.component';
import { CustomSteplineComponent } from './charts/custom-stepline/custom-stepline.component';
import { DateInputComponent } from './form/date-input/date-input.component';
import { CameraModalComponent } from './components/camera-modal/camera-modal.component';
import { DevicePopoverComponent } from './components/device-popover/device-popover';
import { SensorPopoverComponent } from './components/sensor-popover/sensor-popover';
import { TurnDetalleComponent } from './components/turn-detalle/turn-detalle.component';
import { SegmentComponent } from './components/camera-modal/segment/segment.component';
import { StatifChartComponent } from './components/camera-modal/statif-chart/statif-chart.component';

@NgModule({
    imports: [
        CommonModule,
        NgApexchartsModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CoreCommonModule,
        CardSnippetModule,
        SweetAlert2Module,
        NgSelectModule,
        Ng2FlatpickrModule,
        GoogleMapsModule,
        // CameraModule,
        // ComponenteModule,
        VariableHistoryModule,
        ChartsModule,
        ContentHeaderModule,
        ChartjsModule
    ],
    exports: [
        BasicTableComponent,
        CustomTreeComponent,
        CustomPieComponent,
        InstallationMapComponent,
        CustomLineComponent,
        StatsHorizontalCardComponent,
        DetailPieComponent,
        DetailPieCardComponent,
        CustomSteplineComponent,
        DateInputComponent,
        CameraModalComponent,
        DevicePopoverComponent,
        SensorPopoverComponent,
        TurnDetalleComponent,
        SegmentComponent,
        StatifChartComponent
    ],
    declarations: [
        BasicTableComponent,
        CustomTreeComponent,
        CustomPieComponent,
        InstallationMapComponent,
        CustomLineComponent,
        StatsHorizontalCardComponent,
        DetailPieComponent,
        DetailPieCardComponent,
        CustomSteplineComponent,
        DateInputComponent,
        CameraModalComponent,
        DevicePopoverComponent,
        SensorPopoverComponent,
        TurnDetalleComponent,
        SegmentComponent,
        StatifChartComponent
    ],
    providers: [],
})
export class SharedModule { }
