<!-- <div class="segment-wrapper" (window:resize)="onResize($event)" [style.width.px]="width">
    <ion-segment (ionChange)="segmentChanged($event)" color="medium" [attr.value]="default" scrollable="true">
      <ion-segment-button value="{{segment.value}}" *ngFor="let segment of segmentList">
        <ion-label>{{segment.text}}</ion-label>
      </ion-segment-button>
    </ion-segment>
</div> -->

<button *ngFor="let segment of segmentList" [ngClass]="{selected: segment == selectedSegment}" (click)="segmentChanged(segment)">
  <span>{{segment.text}}</span>
</button>