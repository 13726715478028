import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pickr') picker;
  @Input() label:string;
  @Input() form: FormGroup;
  @Input() config: FlatpickrOptions
  @Input() name: string;

  private subscriptions: Subscription[] = [];

  constructor() { }

  ngOnInit(): void {   
    
    this.subscriptions.push(
      this.form.controls[this.name].valueChanges.subscribe((value)=>{
        console.log("picker setting date to: ", value)
        if(value) this.picker.flatpickr.setDate(value);        
      })
    )
  }

  ngAfterViewInit(): void {
    const newDate = this.form.controls[this.name].value;
    if(newDate) this.picker.flatpickr.setDate(newDate);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }

}
