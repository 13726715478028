<!-- Google Maps Marker Circle Polygon Component -->
<google-map *ngIf="markersLoaded"
    [options]="mapOptions" 
    height="400px"
    width="'100%'"
    [center]="markerCirclePolygonCenter"
    [zoom]="markerCirclePolygonZoom"
>
    <!-- <map-circle [options]="mapCircleOptions" [center]="mapCircleCenter"></map-circle> -->
    <!-- <map-polygon [options]="mapPolygonOptions" [paths]="mapPolygonPaths"></map-polygon> -->
    <map-marker *ngFor="let marker of markers" [position]="marker.position" [options]="marker" (mapClick)="marker.onclick()"></map-marker>
    <map-polyline *ngFor="let polyLine of polyLines" [options]="polyLine" [path]="polyLine.path" (polylineClick)="polyLine.onclick()"></map-polyline>
</google-map>
<!--/ Google Maps Marker Circle Polygon Component -->

<div id="mapLegend" [hidden]="!mapLegendLoaded">
    <h2>Leyenda</h2>
</div>

<!-- Modal -->
<ng-template #modalTemplate let-modal>
    <div class="modal-header">
        <!-- <h5 class="modal-title" id="myModalLabel160">Large Modal</h5> -->
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <!-- Tart lemon drops macaroon oat cake chocolate toffee chocolate bar icing. Pudding jelly beans
        carrot cake pastry gummies cheesecake lollipop. I love cookie lollipop cake I love sweet gummi
        bears cupcake dessert. -->
        <ng-container [ngTemplateOutlet]="dataTemplate" [ngTemplateOutletContext]="{ $implicit: modalData }"></ng-container>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            Aceptar
        </button>
    </div>
</ng-template>

<ng-template #dataTemplate let-data>
    <!-- {{data|json}} -->
    <div [ngSwitch]="data['type']">
      <app-device-popover *ngSwitchCase="'device-popover'" [location]="data['location']"></app-device-popover>
      <app-camera-modal *ngSwitchCase="'camera'" [location]="data['location']"></app-camera-modal>
      <app-variable-history *ngSwitchCase="'variable'" [location]="data['location']"></app-variable-history>
    </div>
</ng-template>