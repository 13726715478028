import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { ModalController } from '@ionic/angular';
import { SensorDataService } from '../../../../core/device/services/sensor-data.service';
import * as _ from 'lodash';
import { formatTimestamp, yyyymmddhhmm } from '../../../../core/util/date.util';

@Component({
    selector: 'app-device-popover',
    templateUrl: 'device-popover.html',
    styleUrls: [
        './device-popover.scss'
    ]
})

export class DevicePopoverComponent implements OnInit {

    @Input() location;
    selectedDevice;
    sensorList: any[] = [];
    loading = true;
    constructor(
        // private modalController: ModalController,
        private sensorDataService: SensorDataService
    ) { }

    ionViewDidEnter(){
        // this.sensorDataService.instant({}).subscribe((response)=>{
        //     console.log("response for instant")
        //     console.dir(response);
        // })
    }

    ngOnInit() {
        // console.log("location: ");console.dir(this.location);
        // console.log("device: ");console.dir(this.location.Devices[0])
        this.selectDevice(this.location.Devices[0]);
    }

    selectDevice(device){
        this.selectedDevice = device;
        this.sensorDataService.instant({
            device_id: device.id
        }).subscribe((response:any)=>{
            this.loading = false;
            console.log("response for instant")
            console.dir(response);
            const data = response.itemList;
            this.sensorList = data.map((row)=>{
                return {
                    name: _.get(row, 'SensorType.name', 'desconocido'),
                    value: row.value,
                    ts: yyyymmddhhmm(new Date(row.create_date))
                }
            })
        })
    }

    close(){
        // this.modalController.dismiss(null);
    }
}