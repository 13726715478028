import { Component, OnInit, Input } from '@angular/core';
// import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-turn-detalle',
  templateUrl: './turn-detalle.component.html',
  styleUrls: ['./turn-detalle.component.scss'],
})
export class TurnDetalleComponent {

  constructor() { }

  ngOnInit() {}

  cerrar(){
    // this.modalCtrl.dismiss();
  }

}
