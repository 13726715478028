import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, combineLatest, interval, of } from 'rxjs';
import { InstallationService } from '../../../../core/installation/services/installation.service';
import * as _ from 'lodash';
import { debounceTime, filter, switchMap, take } from 'rxjs/operators';
import { AlarmService } from '../../../../core/alarm/services/alarm.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryParamsModel } from '../../../../core/_base/crud';
import Swal from 'sweetalert2';
import { AlarmActionService } from '../../../../core/alarm/services/alarm-action.service';
import { CommentTypeService } from '../../../../core/alarm/services/comment-type.service';
import { mapOptions } from '../../../../core/config/map.config';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-installation-map',
  templateUrl: './installation-map.component.html',
  styleUrls: ['./installation-map.component.scss']
})
export class InstallationMapComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('modalTemplate', { read: TemplateRef }) modalTemplate:TemplateRef<any>;
  @Input() installationList: any[] = null;
  @Input() alarmList: any[] = null;
  @Input() zoneColorByLatestStatus: boolean = true;
  @Input() showLegend: boolean = false;
  mapLegendLoaded: boolean = false;
  mapOptions = mapOptions;
  /**
   * Marker Circle Polygon Component
   */
  public markerCirclePolygonCenter = { lat: 37.421995, lng: -122.084092 };
  public markerCirclePolygonZoom = 15;
  public mapCircleCenter: google.maps.LatLngLiteral = { lat: 37.421995, lng: -122.084092 };

  public mapCircleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    radius: 200,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    draggable: false,
    center: { lat: 37.421995, lng: -122.084092 }
  };

  // Define the LatLng coordinates for the polygon's  outer path.
  private polygonCoords = [
    { lat: 37.421834, lng: -122.079971 },
    { lat: 37.421672, lng: -122.07273 },
    { lat: 37.419884, lng: -122.079213 }
  ];

  public mapPolygonPaths = [this.polygonCoords];

  public mapPolygonOptions = {
    strokeColor: '#3164bf',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    radius: 200,
    fillColor: '#3164bf',
    fillOpacity: 0.35,
    draggable: false,
    center: { lat: 37.421995, lng: -122.084092 }
  };

  installationList_: any[] = [];
  installations$ = new BehaviorSubject(null);
  mapMode = 'satellite' // normal | satellite

  private subscriptions: Subscription[] = [];
  markersLoaded: boolean = false;
  deviceList: any[];
  markers: any[];
  polyLines: any[];

  private customIconPath = 'assets/images/misc/';
  public customIconZoom = 13;
  public customIconCenter: google.maps.LatLngLiteral = { lat: 37.421995, lng: -122.084092 };
  public customIcon2Center: google.maps.LatLngLiteral = { lat: 37.431997, lng: -122.094097 };
  customIcon2Options = {
    icon: this.customIconPath + 'leaf-green.png'
  };
  customIconOptions = {
    icon: this.customIconPath + 'leaf-red.png'
  };

  @ViewChild(GoogleMap) map!: GoogleMap;


  modalData: any;
  firstLoad: boolean = true;

  constructor(
    private installationService: InstallationService,
    private alarmService: AlarmService,
    private router: Router,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("NG CHANGES INSTALLATION");console.dir(changes);
    if(changes['installationList']){
      const cInstallationList = changes['installationList'].currentValue;
      const pInstallationList = changes['installationList'].previousValue;
      if(cInstallationList && cInstallationList.length > 0){
        this.installationList_ = cInstallationList;
      }else if(cInstallationList != null){
        this.installationList_ = [cInstallationList];
      }
      this.installationList_ = this.installationList_.filter(installation=>installation.Locations.some(location=>location.status_id == 1));
      this.setLocations(this.getVisibleLocations(this.installationList_));
      this.installations$.next(this.installationList_);
    }
  }

  ngOnInit(): void {

    if(this.installationList){
      let installationList = this.installationList;
      if(this.installationList.length == undefined){
        installationList = [this.installationList];
      }
      this.installationList_ = installationList;
      console.log("INSTALLATION LIST AS INPUT -> SET LOCATIONS")
      this.setLocations(this.getVisibleLocations(this.installationList_));
      this.installations$.next(installationList);
    }else{

      this.subscriptions.push(
        this.installationService.getAll().subscribe(installationList=>{
          console.log('MARK1')
          console.dir(installationList);
          this.installationList_ = installationList.filter(installation=>installation.Locations.some(location=>location.status_id == 1));
          
          // if(this.installationList_.some(installation=>installation.installation_type_id != 5)){
            // this.scheduledWorksEnabled = true
            // }
          
          this.setLocations(this.getVisibleLocations(this.installationList_));
          this.installations$.next(this.installationList_);
        })
      )
    }
    this.mapMode = 'satellite';
    console.log("ng on init map mode: " +this.mapMode)
    // this.toggleMapMode();
    // setTimeout(()=>this.toggleMapMode(), 200);
    // interval(500).subscribe(()=>{
    //   console.log(`mapas.page.ts map mode: ${this.mapMode}`)
    // })

    this.subscriptions.push(
      this.installations$.pipe(
        filter(installationList=>installationList!=null),
        switchMap(()=>this.alarmList?of(this.alarmList):this.alarmService.alarms$),
        filter(alarms=>alarms!=null ),
        filter((alarms)=>this.installationList_!=null)
      ).subscribe((alarmList)=>{
        const locationList = this.getVisibleLocations(this.installationList_)
        // this.setLocations(locationList)
        // const locationList = _.flatten(this.installationList_
        //   .map(installation=>installation.Locations));
        locationList.forEach(location=>{
          if(location.location_type_id == 3){
            //En caso de haber definido una lista de alarmas se guia por si existe una alarma para la zona
            if(this.alarmList == null? alarmList.some(alarm=>alarm.Device.Location.id == location.id) : location.siac_status_id == 4){
              console.log("ALARM LIST NOT AS INPUT")
              //tipo zona con alarma
              location['stroke-color'] = '#bf0202'
              console.log(`SE ENCUENTRA ALARMA EN UNA ZONA ${location.name}: `);console.dir(alarmList.find(alarm=>alarm.Device.Location.id == location.id))
            }else{
              location['stroke-color'] = this.getLocationColor(location);
            }
          }else{
            delete location['stroke-color']
          }
        })
        this.setLocations(locationList);
        //TEST ACTUALIZACIÓN DE MAPA
        // setTimeout(()=>{
        //   const location = locationList.find(location=>location.id==35); //infra 7
        //   location['stroke-color'] = '#bf0202';
        //   this.setLocations(locationList);
        // }, 1000*10)
    }));
  }

  statusMap = {
    1:	'APAGADO',
    2:	'ARMADO',
    3:	'DES-ARMADO',
    4:	'ALARMADO',
    5:	'BLOQUEADO',
  }

  addMapLegends(){
    this.subscriptions.push(
      interval(100).pipe(
        filter(()=>this.map!=undefined),
        take(1)
      ).subscribe(()=>{
        /* Adding Map Legends */
        var legend = document.getElementById('mapLegend');
        Object.keys(this.statusMap).forEach(statusId=>{
    
          const div = document.createElement('div');
          div.innerHTML = `<span><img src="${this.getLocationImageUrl({siac_status_id: statusId})}"> <span style="color: ${this.getLocationColor({siac_status_id: statusId})}">---</span> ${this.statusMap[statusId]}</span>`;
          legend.appendChild(div);
        })
        /* Push Legend to Right Top */
        this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend);
        setTimeout(()=>{

          this.mapLegendLoaded = true;
        }, 1500)
      })
    )
  }
  
  getVisibleLocations(installationList){
    return _.flatten(installationList
      .map(installation=>installation.Locations))
      .filter(location=>location.status_id==1);
  }

  getLocationColor(location){
    console.log("COLOR BY SIAC STATUS ID: ")
    const colorMap: {[siac_status_id: number]: string} = {
      1: '#000000',
      2: '#32a852',
      3: '#787878',
      4: '#bf0202',
      5: '#0052A5'
    }
    // if(this.markersLoaded == false)return '#32a852'
    if(this.zoneColorByLatestStatus)
      return colorMap[location.siac_status_id];
    else
      return '#32a852'
  }

  ngAfterViewInit(): void {
    if(this.showLegend)
      this.addMapLegends();
  }
  
  getBounds(markers){
    let north;
    let south;
    let east;
    let west;
  
    for (const marker of markers){
      // set the coordinates to marker's lat and lng on the first run.
      // if the coordinates exist, get max or min depends on the coordinates.
      north = north !== undefined ? Math.max(north, marker.position.lat) : marker.position.lat;
      south = south !== undefined ? Math.min(south, marker.position.lat) : marker.position.lat;
      east = east !== undefined ? Math.max(east, marker.position.lng) : marker.position.lng;
      west = west !== undefined ? Math.min(west, marker.position.lng) : marker.position.lng;
    };
  
    const bounds = { north, south, east, west };
  
    return bounds;
  }

  setLocations(locationList){
    console.log("LOCATION LIST ");console.dir(locationList);
    // this.markersLoaded = false;
    const polyLines = []
    const markers = [];
    let deviceList = [];
    locationList.forEach(location=>{
      deviceList = deviceList.concat(location.Devices);
      if(location.location_type_id == 3){
        const polyLine = {
          path: [
            {
              lat: Number(location.google_lat1),
              lng: Number(location.google_lng1),
            },
            {
              lat: Number(location.google_lat2),
              lng: Number(location.google_lng2)
            }
          ],
          strokeWeight: '2',
          visible: true,
          strokeColor: location['stroke-color'] || this.getLocationColor(location),
          strokeOpacity: 0.8,
          onclick: ()=>{
            console.log("path clicked => location: ");console.dir(location);
            this.openZoneModal(location);
          }
        }
        polyLines.push(polyLine)

        const marker = {
          position: {
            lat: Number(location.google_lat),
            lng: Number(location.google_lng),
          },
          // position: { lat: -33.4166668, lng: -70.6092060 }, title: 'Marker 2',
          visible: true,
          
          title: location.name,
          label: { color: 'red', text: location.name, fontSize: '14px'},
          onclick: ()=>{
            this.locationClicked(location);
          },
          // icon: null
          icon: { url: '', scaledSize: { width: 0, height: 0 }, labelOrigin: { x: 0, y: 0 } },
          // info: { contenido: 'Marker info window content', title: location.name, accion: 'console.log("hola")' },
        }
        markers.push(marker);
        // delete marker['position']
      }else{
        const marker = {
          position: {
            lat: Number(location.google_lat),
            lng: Number(location.google_lng),
          },
          // position: { lat: -33.4166668, lng: -70.6092060 }, title: 'Marker 2',
          visible: true,
          
          title: location.name,
          label: { color: 'red', text: location.name, fontSize: '10px'},
          icon: { url: this.getLocationImageUrl(location), scaledSize: { width: 40, height: 40 }, labelOrigin: { x: 20, y: -10 } },
          onclick: ()=>{
            this.locationClicked(location);
          }
          // info: { contenido: 'Marker info window content', title: location.name, accion: 'console.log("hola")' },
        }
        markers.push(marker);
      }
    });
    if(markers && markers.length>0){
      this.markerCirclePolygonCenter.lat = markers[0].position.lat;
      this.markerCirclePolygonCenter.lng = markers[0].position.lng;
    }
    this.markers = Array.from(markers);
    this.polyLines = Array.from(polyLines);
    console.dir(this.markers);
    console.dir(this.polyLines)
    this.deviceList = deviceList;
    setTimeout(()=>{
      // console.log("MARKERS LOADED!!!")
      this.markersLoaded = true
      if(this.firstLoad){
        setTimeout(()=>{
          console.dir(this.map)
          const bounds = this.getBounds(this.markers);
          console.log("FIRST LOAD ", bounds)
          this.map.googleMap.fitBounds(bounds);
          this.firstLoad = false;
        }, 500)
      }
      this.cdr.detectChanges();

    }, 100);
    this.cdr.detectChanges();
  }

  locationClicked(location){
    if(location.location_type_id == 1){//camarilla
      this.router.navigate(['/app/vault'],{
        queryParams: {
          location: location.id
        }
      })
    }else if(location.location_type_id == 2){//SENSOR
      //abre componente @componente/sensor
      this.openSensorModal(location)
    }else if(location.location_type_id == 3){ //ZONA
      //abre modal dashboard src/app/@paginas/variable-history/variable-history.page.html
      this.openZoneModal(location);
    }else if(location.location_type_id == 4){//SATIF-NODE
      this.openNodeModal(location);
    }else if(location.location_type_id == 5){//SIAC-GW
      this.openGWModal(location);
    }else if(location.location_type_id == 6){//SIAC-RELAY
      this.openRelayModal(location);
    }else if(location.location_type_id == 7){//camara
      this.openCameraModal(location);
    }
  }

  getLocationImageUrl(location){
    const urlMap = {
      5: '../../../../../../assets/icons/icon-mapa-1.svg', //bloqueado
      4: '../../../../../../assets/icons/icon-mapa-1.svg', //alarmado
      3: '../../../../../../assets/icons/icon-mapa-3.svg', //desarmado
      2: '../../../../../../assets/icons/icon-mapa-2.svg', //armado
      1: '../../../../../../assets/icons/icon-mapa-7.svg', //apagado
    };
    return urlMap[location.siac_status_id];
  }

  async openFilterModal(){
    console.log("open filter modal")
    this.openModal(this.modalTemplate, {})
    // const modal = await this.modalController.create({
    //   component:  FilterMapModal,
    //   cssClass: 'mimodaldetail',
    //   componentProps: {
    //     installationList: this.installationList_
    //   },
    // });

    // modal.onDidDismiss()
    //   .then((data) => {
    //     console.dir(data);
    //     const filteredLocationList = data['data']; // Here's your selected user!
    //     if(filteredLocationList){
    //       this.setLocations(filteredLocationList);
    //       this.counter = filteredLocationList.length;
    //     }
    // })
    // return await modal.present();
  }

  async openSensorModal(location){
    console.log("open filter modal")
    this.openModal(this.modalTemplate, {location, type: 'device-popover'})
    // const modal = await this.modalController.create({
    //   component:  DevicePopoverComponent,
    //   cssClass: 'mimodaldetail',
    //   componentProps: {
    //     location
    //   },
    // });
    // return await modal.present();
  }

  async openZoneModal(location){
    console.log("open zone modal")
    try{
    this.openModal(this.modalTemplate, {location, type: 'variable'})
    }catch(err){
      console.dir(err);
    }
    // const modal = await this.modalController.create({
    //   component:  VariableHistoryPage,
    //   cssClass: 'mimodaldetail',
    //   componentProps: {
    //     location
    //   },
    // });
    // return await modal.present();
  }

  async openCameraModal(location){
    console.log("open camera modal")
    this.openModal(this.modalTemplate, {location, type: 'camera'})
    // const modal = await this.modalController.create({
    //   component:  CameraModalComponent,
    //   cssClass: 'mimodaldetail',
    //   componentProps: {
    //     location
    //   },
    // });
    // return await modal.present();
  }

  async openNodeModal(location){
    console.log("open filter modal")
    this.openModal(this.modalTemplate, {location, type: 'device-popover'})
    // const modal = await this.modalController.create({
    //   component:  DevicePopoverComponent,
    //   cssClass: 'mimodaldetail',
    //   componentProps: {
    //     location
    //   },
    // });
    // return await modal.present();
  }
  
  async openGWModal(location){
    console.log("open filter modal")
    this.openModal(this.modalTemplate, {location, type: 'device-popover'})
    // const modal = await this.modalController.create({
    //   component:  DevicePopoverComponent,
    //   cssClass: 'mimodaldetail',
    //   componentProps: {
    //     location
    //   },
    // });
    // return await modal.present();
  }

  async openRelayModal(location){
    console.log("open filter modal")
    this.openModal(this.modalTemplate, {location, type: 'device-popover'})
    // const modal = await this.modalController.create({
    //   component:  DevicePopoverComponent,
    //   cssClass: 'mimodaldetail',
    //   componentProps: {
    //     location
    //   },
    // });
    // return await modal.present();
  }
  // modal Open Small
  openModal(template, data) {
    try{
    this.modalData = data;
    console.log("modal data: ");console.dir(this.modalData)
    this.modalService.open(template, {
      centered: true,
      size: 'lg' // size: 'xs' | 'sm' | 'lg' | 'xl'
    });
  }catch(err){
    console.dir(err);
  }
  }


  ngOnDestroy(){
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}