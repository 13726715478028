import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { colors } from '../../../../colors.const';

export interface Detail{
  icon: string;
  iconColor: 'primary'|'warning'|'success'|'danger';
  value: number | string;
  unit: string | null;
  attribute: string;
}

export interface PieData{
  [attr:string]: {
    icon: string;
    iconColor: string;
    value: number | string;
  }
}

@Component({
  selector: 'app-detail-pie',
  templateUrl: './detail-pie.component.html',
  styleUrls: ['./detail-pie.component.scss']
})
export class DetailPieComponent implements OnInit, OnChanges{
  @Input() data: PieData;
  @Input() unit: string;

  detailList: Detail[] = [];

  private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
  private successColorShade = '#28dac6';
  private warningLightColor = '#FDAC34';

  // doughnut Chart
  public doughnutChart = {
    chartType: 'doughnut',
    options: {
      responsive: false,
      responsiveAnimationDuration: 500,
      cutoutPercentage: 60,
      aspectRatio: 1.4,
      legend: { display: false },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[0].labels[tooltipItem.index] || '',
              value = data.datasets[0].data[tooltipItem.index];
            var output = ' ' + label + ' : ' + value;
            return output;
          }
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      }
    },

    datasets: [
      {
        labels: [],
        data: [],
        backgroundColor: [this.successColorShade, this.warningLightColor, colors.solid.primary, colors.solid.danger, colors.solid.success],
        borderWidth: 0,
        pointStyle: 'rectRounded'
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
    this.ngOnChanges(null);
  }
  ngOnChanges(changes: SimpleChanges){
    if(this.data){
      this.updateDatasets();
      this.updateDetails();
    }
  }

  updateDatasets(){
    this.doughnutChart.datasets[0].data = Object.values(this.data).map(v=>v.value);
    this.doughnutChart.datasets[0].labels = Object.keys(this.data);
    this.doughnutChart.datasets = Array.from(this.doughnutChart.datasets);
  }

  updateDetails(){
    const detailList = [];
    for(let attr of Object.keys(this.data)){
      detailList.push({
        attribute: attr,
        unit: this.unit,
        value: this.data[attr].value,
        icon: this.data[attr].icon,
        iconColor: this.data[attr].iconColor
      })
    }
    this.detailList = detailList;
  }
}
